

.sitemap-ul {
  list-style-type: disc;
  padding-left: 30px;
}

.sitemap-content-half {
  width: 62%;
  float: left;
}

.sitemap-aside {
  background-color: #e1e7f0;
  padding: 20px;
  .tp-heading {
    text-align: center;
    padding: 8px 15px;
    font-size: 2rem;
    background: #00b67a;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .fa-lock {
      font-size: 1.7rem;
      padding-right: 10px;
    }
  }
  .fa-user {
 //   color: #00b67a !important;
  }
  .trust-pilot-logo {
    a {
      display: block;
      background: url(https://ww3.nationaldebtrelief.com/static/ndr_app/images/trust-pilot-logo-144.png) center no-repeat !important;
      background-size: auto auto;
      height: 35px;
      margin: 0 auto;
      background-size: 100%;
    }
  }
  .get-started-form {
    padding: 20px;
    margin: auto 0;
    width:100%;
    background: transparent;
    h3 {
      color: #18027A;
    }
  }
  .sitemap-text-under-form {
    p {
     // width: 355px;
      padding: 15px;
      padding-right: 15px;
      text-align: justify;

    }
  }
  @media screen and (max-width: 1350px) {
    .sitemap-text-under-form{
    p{
      width:100%; 


    }
    }
  
  }
  .sidebar-video {
    background: #fff;
    padding: 10px 10px 4px;
  }
  .effect2 {
    position: relative;
    &:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
    &:after, &:before {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 15px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #444;
      -webkit-box-shadow: 0 15px 10px #444;
      -moz-box-shadow: 0 15px 10px #444;
      box-shadow: 0 15px 10px #444;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
  }

  .bbb-form {
    margin: 40px auto;
  }

  .sitemap-phone {
    width: 100%;
    padding: 0px;
   
    .sidebar-phone-graphic {
      line-height: 22px;
      padding: 20px;
      margin: 0;
      box-shadow: 0px 0px 6px #999 inset;
      border-radius: 3px 3px 0 0;
      background: url(https://www3.nationaldebtrelief.com/static/ndr_app/images/sidebar-phone-ad.jpg) no-repeat;
      color: #18027A;
      font-size: 1.8rem;
      font-weight: 700;
      font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      text-transform: none;
      text-align: left;
    }
    .phone {
      margin-top: -2px;
      padding: 11px 15px 13px;
      border-radius: 3px;
      background: #18027A;
      color: #fff;
      font-size: 2.7rem;
      line-height: 27px;
      font-weight: 800;
      font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
}

