
  .footer {
    background-color: #101930;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
    font-size: 15px;
    border-top: 5px solid #ff5500;
    
    > div {
      max-width: 1416px;
      margin: 0 auto;
    }
    .content {
      margin-bottom: 10px;
      display: flex;
      width:100%;
      .info {
        width: 100%;
        .address {
          font-family: Product Sans;
          color: #acacac;
          text-align: left;
  
          div:nth-child(1) {
            color: white;
          }
        }
        .phone { text-align: left; }
        .phone > div { margin-top: 40px; a{color: #e2e2e2; } }
        .phone > a {
          font-size: 15px;
          margin: 0;
          padding: 0;
          color: #a8c2e2;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .social {
        width: 100%;
        display: flex; 
        justify-content: flex-end;
    
        padding-right:0;
       // margin:0 auto; 
     //   margin-right:0;
      
        .text {
          display: flex; 
          font-family: BrandonGrotesque;
          line-height: 1.87;
          letter-spacing: 1.5px;
          text-align: right;
          color: #e2e2e2;
          white-space: nowrap;
          max-width:1300px;
        }
  
        a { padding: 5px; }
        a:first-child { padding-left: 40px; }
      }
    }
  
    .privacy {
      > .text {
        font-family: Product Sans;
        font-size: 15px;
        display: flex; 
        justify-content: space-between;
        border-top: 1px solid #313644;
        color: #acacac;
        a {
          text-decoration: none;
          color: #acacac;
        }
      }
    }
    .disclaimer {
      margin-top: 30px;
      padding: 20px;
      .text {
        font-size: 10px;
        color:  #cacaca;
        line-height: 1.3;
        text-align: left;
      }
    }
  }



@media screen and (max-width: 768px) {

  .footer {
    height: auto;
    font-size: 10px;
    padding: 20px !important;
    a > img { 
      height: 25px;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .info {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #dddddd;
        padding: 20px;
        .address {
          width: 100%;
          padding-left:0;
        }
        .phone { text-align: left; }
        .phone > div { margin-top: 0; }
        .phone > a { font-size: 10px; }
      }
      .social {
        width: 100%;
        display: flex; 
        justify-content: flex-start;
        flex-direction: column;
        padding: 20px;
        .text {
          width: 100%;
          display: flex; 
          flex-direction: column;
          font-size: 10px;
          text-transform: uppercase;
          text-align: left;
        
          a:first-child { padding-left: 0; }
          .icons {
            padding-top: 15px;
           
          }
        }
      }
    }
    .privacy {
      padding-top: 15px;
      padding-bottom: 15px;
      .text {
        font-size: 13px !important;
        display: flex;
        justify-content: center;
        flex-direction: column;

        div {
          text-align: center;
          padding-top: 10px;
        }
      }
    }
  }
  .footer .disclaimer{
    margin-top:0;
    padding-bottom:60px;
  }
  .footer .content{
    padding:0;
  }

}

@media screen and (min-width: 668px) and (max-width: 1024px) {
  .footer {
    // padding: 20px;
  }
}