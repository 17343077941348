.slideup-mobile-form {
    display:none !important;
    position: fixed;
    width: 100%;
    bottom: 0;
    display: none;
    left: 0;
    margin: 0;
    height: auto;
    z-index: 99;
    max-height: 65px;
    transition: all .5s;
    margin-left:initial !important;
    margin-right:initial !important;
    background:white; 
    .get-started-form-input-invalid {
        background-color: #ffdfdf !important;
        //background-color: #ffdfdf !important; // red
       // background-color: #ffffc1 !important;
      }
    .form-group{
        margin-bottom:0;
        position: relative;
        svg{
          font-size: 18px;
          color: #000000;
          right: 15px;
          top: 6px;
          padding: 0;
          position: absolute;
          pointer-events: none;
          font-size: 24px;
        }
    }
    input{
        background: #fff;
        border: 1px solid #ddd;
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        padding: 13px 15px;
        border-radius: 0;
        width: 100%;
        line-height: normal;
        margin-bottom: 10px;
    }
    select{
        position:relative; 
        overflow:hidden; 
        width:100%; 
        font-size:12px;
        line-height:normal;
        border-radius:0;
        -webkit-appearance:none;
        appearance:none;
        &:after{
            content: "\f0d7";
            font-family: "FontAwesome";
        
            font-size: 18px;
            color: #000000;
            right: 15px;
            top: 11px;
            padding: 0;
            position: absolute;
            pointer-events: none;
            font-size: 24px;
        }
       
    }
    button{
        background-color:#f20000 !important; 
        color:#fff; 
        outline:none;
        border-radius: 0;
        border: 0 !important;
    cursor: pointer;
    font-size: 16px;
    margin-top:10px;
    -webkit-appearance:none;
    box-shadow:none !important; 
    font-weight: 700;
    padding: 15px 45px;
    text-decoration: none;
    white-space: normal;
    width: 100%;
    text-transform: uppercase;
    line-height: 22px;
    display: inline-block;
    height: auto;
   
    &:hover{
       background-color: #f20000 !important;
       border:0 !important;
       box-shadow:none; 
    }
    


.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
        background-color: #f20000 !important;
        border:0 !important;
        box-shadow:none; 
     }
     .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
        background-color: #f20000 !important;
        box-shadow:0; 
        border:none !important;
     }
    }
    p{
     //   padding:0 0 40px; 
        margin:0; 
    }
    .form-disclaimer{
        color:#fff; 
        font-size: 12px;
    line-height: 12px;
    display: block;
    margin-top: 10px;
    text-align: justify;
    font-family: Helvetica Neue
    }
   

  }
  .get-started-form-submit, .get-started-form-submit:hover{
    background-color: red !important;
    color: #fff;
  }
  .fa{
    display: inline-block;
 //  font-family:"FontAwesome";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }
  .fa-angle-down:before {
 //   font-family: "FontAwesome";
 //   content: "\f105";
}

  .get-started-form-submit{
    border: 0;
    font-family:Arial; 
    cursor: pointer;
    font-size: 16px !important;
    font-size: 1.6rem;
    font-weight: 700 !important;
    padding: 15px 45px;
    text-decoration: none;
    white-space: normal;
    width: 100%;
    text-transform: uppercase;
    line-height: 1;
    display: inline-block;
    height: auto;
  }
  
  .slideup-form-title {
   // padding: 0 0 0 3.5%;
    border-top: 3px solid #fc0d1b;
   
    color: #fff;
    overflow: hidden;
    .btn.get-started-form-submit {
      float: left;
      width: 70%;
      padding: 10px 5px;
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 1.5rem;
    }
    .bbb-block{
        display:flex;
        padding-top:10px;
        &>div{
            margin:auto;
        }
        .lock{
            text-align: center;
        color: #505378;
        font-size: 12px;
            font-family: Arial;
        margin: 0 0 8px;
        font-weight: 600;
        padding-bottom: 0;
        svg{
            color: #505378;
        }
    }
    }

  }
  
  .mobile-toggle {
    color: #fff;
    border: none;
    font-size: 48px;
    font-weight:normal;
    background: #2d294a;
    display: block;
    width: 70px;
    height: 64px;
    float: right;
    text-align: center;
    line-height: 52.8px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.5s;
  }
  
  @media screen and (max-width: 767px) {
    .slideup-mobile-form {
      display: block !important;
    }
  }
  
  .active-slider-form {
   // overflow: scroll;
    max-height: 100%;
    height: 100%;
    .mobile-toggle {
      transform: rotate(1deg);
    
    }
    .slideup-form-title {
        .get-started-form-submit {
       display: none;
      }
    }
  }
  
  .slideup-form-wrap {
    width: 100%;
    float: none;
    position: static;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 45px;
    background:black;
    button{
        background-color:red; 
        color:#fff; 
        outline:none;
        font-family:Arial;
        border-radius: 0;
        border: 0;
    cursor: pointer;
    font-size: 16px;
    margin-top:10px;
    
    font-weight: 700;
    padding: 15px 45px;
    text-decoration: none;
    white-space: normal;
    width: 100%;
    text-transform: uppercase;
    line-height: 22px;
    display: inline-block;
    height: auto;
    &:hover{
       background-color: #f20000;
    }
    }


    
  }
  
  .slideup-toggle {
    background: #1f1e30;
    height:64px;
    padding-left:15px;
    h3 {
      color: #fff;
      width: auto;
      float: left;
      margin: 5px 0 0;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .mobile-get-started-form {
    display: block;
    max-width: 100% !important;
  }
  /*.get-started-form {
    background: #000;
    display: flex;
    flex-direction: column;
    padding: 0 40px 20px;
    width: 355px;
    float: right;
    margin-top: 0;
    position: relative;
    z-index: 1;
}*/