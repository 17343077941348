
.ca-widget{
    background:white;
     span.ca-heading {
        background: #176199;
        padding: 10px 15px;
        font-size: 20px;
       
        text-align: center;
        color: #fff;
        display: block;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 300;
    }
     .ca-body {
        background: #fff;
        padding: 12px;

    }
    .sidebar-trustpilot .fa {
        margin-right: 10px;
    }
     .smallquote {
        margin-bottom: 0px;
        border: none;
        padding: 0px;
    }
    .smallquote .quote-content-wrap {
        float: right;
        width: 82%;
        margin-bottom: 5px;
    }
    .sq-meta{
        overflow: hidden;
        clear: both;
    }
   
     .smallquote .fa-user {
        color: #176199;
    }
    .smallquote .fa-user {
        
        padding:10px;
        border: 1px solid;
        border-radius: 50%;
        width: 40px;
        height:40px;
        float: left;
        font-size: 20px;
       
        width: auto;
        display: block;
    }
    .five-star {
        float: right;
        margin-bottom:0;
        img{
            clear: unset;
    margin: 0 !important;
    display: inline;
        }
    }
    .fa-user:before {
        content: "\f007";
    }
    .pq-attr {
        text-align: right;
        float: right;
        clear: both;
        line-height: 1.3;
    }
    .ca-logo{
       
        overflow: auto;
        padding-bottom: 20px;
        padding-right: 12px;
    }

    .ca-logo a {
        display: block;
        background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2017/10/consumer-affairs.png) center no-repeat #fff;
        width: 180px;
        height: 30px;
        padding: 6px 0;
        border-top: 1px solid #ccc;
        text-indent: -9999em;
        background-size: 100% auto;
        margin: 0 0 0 auto;
        text-decoration:none;
    }

}
