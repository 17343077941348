@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap');
/*
Site Footer
---------------------------------------------------------------------------------------------------- */

.site-footer {
	color: #222;
	font-size: 12px;
	font-size: 1.2rem;
	padding: 0px 0;
	text-align: center;
	text-transform: uppercase;
}

.site-footer .wrap {
	max-width: 100%;
}

.site-footer a {
	color: #222;
}

.site-footer p {
	margin: 0;
}

/* Footer Affiliations */

.footer-affiliations {
	border-top: 1px solid #DEE4ED;
	background: #fff;
	overflow: hidden;
	clear: both;
	padding: 45px 0;
}


.footer-affiliations .bbb-form {
	background-position: 0px -623px;
	width: 139px;
	height: 53px;
	text-indent: 0px !important;
	margin-top: 24px;
}

.footer-affiliations .afcc-form {
	background-position: 0px -693px;
	width: 89px;
	height: 87px;
	margin-top: 8px;
}

.footer-affiliations .top-ten-reviews {
	width: 68px;
	height: 105px;
	margin-top: 0;
	background-position: 0 -794px;
}

.footer-affiliations .top-consumer-reviews {
	width: 113px;
	height: 62px;
	margin-top: 19px;
	background-position: 0 -544px;
}

.footer-affiliations .db {
	margin: 37px 0 0 0;
	width: 238px;
	height: 27px;
	background-position: 0px -923px;
}

.footer-affiliations .wrap {
	max-width: 850px;
	margin: 0 auto;
}

/* Footer Nav */

.footer-nav {
	background: #E1E7F0;
	padding: 30px 0;
}

.footer-nav .wrap {
	max-width: 1070px;
	margin: 0 auto;
}

.footer-nav .menu {
	width: 62%;
	float: left;
}

.footer-nav ul {
	text-align: left;
}

.footer-nav ul li {
	display: inline;
	margin-right: 20px;
}

.footer-nav ul li a {
	color: #000;
	font-family: Arial, sans-serif;
	font-size: 16px;

	text-transform: none;
}

.footer-nav p.copyright {
	width: 35%;
	text-align: right;
	float: right;
	color: #000;
	font-family: Arial, sans-serif;
	font-size: 16px;

	text-transform: none;
}

/* Footer Disclaimer */

.footer-disclaimer {
	background: #F2F5F8;
	padding: 30px 0;
}

.footer-disclaimer .wrap {
	max-width: 1070px;
	margin: 0 auto;
}

.footer-disclaimer p {
	text-align: left;
	color: #000;
	text-transform: none;
	font-size: 10px;

	line-height: 12px;
	font-family: Arial, sans-serif;
}

.apply-new-footer-wrap {
	width: auto;
	padding-top: 30px;
	overflow: hidden;
}

.apply-new-footer {
	width: 1200px;
	margin: 0 auto;
}

.apply-new-footer .cred {
	float: left;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.apply-new-footer .cred > a,
.apply-new-footer .cred > div {
    margin-right: 15px;
}

.apply-new-footer a.bbb-logo {
	float: left;
}

.apply-new-footer a.mcafee-logo {
    margin: 5px 25px 1px 10px;
	float: left;
}

.apply-new-footer a.norton-logo {
	float: left;
	background: url(https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/applynew1/norton-logo.png) no-repeat;
	display: block;
	text-indent: -9999em;
	width: 103px;
	height: 55px;
}

.apply-new-footer .copyright {
	float: right;
}

.apply-new-footer .copyright p {
	font-family: "Roboto", sans-serif;
	font-size: 16px;

	color: #484848;
	font-weight: 400;
	margin-bottom: 0;
}

.apply-new-footer .privacy {
	margin-top: 30px;
	float: left;
	clear: both;
}

.apply-new-footer .privacy p {
	text-align: left;
	font-size: 13px;
	
	line-height: 1.3;
}
.unbounce .privacy a{
    color:rgb(0, 0, 255);
    font-family: 'Roboto', sans-serif;
    font-weight:400;

}
.apply-new-footer .privacy a{
    color:rgb(0, 0, 255);
    font-family: 'Roboto', sans-serif;
    font-weight:400;

}
.apply-new-footer .privacy p{
    color:#000;
      font-family: 'Roboto', sans-serif;
      font-weight:400;
  
  }
.unbounce .privacy p{
  color:#000;
    font-family: 'Roboto', sans-serif;
    font-weight:400;

}
.unbounce .copyright{
    color:rgb(72, 72, 72) !important;
    text-align: center;
}
.unbounce .cred{
    float:none;
}

@media screen and (max-width: 767px){

.unbounce .apply-new-footer{
    width:325px;
    .cred{
        a{
            margin-bottom:25px;
        }
    }
}


}
