.aside-col{
    padding-left:0 !important; 
    padding-right:0 !important;
}
.aside{
    width:100%; 

  //  position:relative; 
  //  background:black;
  &__scrollable {
    position: fixed;
    top: 0px;
    z-index:1;
    margin-right: 30px;
    bottom: auto;
    width: calc(27.4% - 15px);
    //z-index: 999;

    .bbb-block{
        max-width:359px;
     
    }


  }
  &__bottom{
    position: absolute;
    margin-right: 0;
    width: 100%;
    max-width:359px;
   
  }
  
    .bbb-block{
        padding: 15px 0;
    background: #fff;
    height:129px;
    max-width:359px;
    //padding-left:30px;
  &>div{
      width:100%; 
      max-width:359px;
  }

   // margin: 0 -40px -20px;
    .fa{
        display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    margin-right: 5px;
    }
    .lock{
        text-align: center;
        color: #505378;
        font-size: 12px;
       
        margin: 0 0 8px;
        font-weight: 600;
        padding-bottom: 0;
    }
    img{
        display:block; 
        margin:0 auto; 
        width:242px; 
        height:52px;
    }
    }
}
.sticky-form{
    background:#000;
    display:flex; 
    justify-content: flex-start; 
    flex-direction:column; 
    padding-left:30px; 
    padding-right:30px;
    max-width:359px;
  //  width:360px;
    height:512px;
   // padding-top:20px; 
    padding-bottom:37px; 
 
  //  padding:0 45px 20px; 
   // float:right; 
    margin-top:0; 

    z-index:1; 
    .get-started-form-input-invalid {
        background-color: #ffdfdf !important;
        //background-color: #ffdfdf !important; // red
       // background-color: #ffffc1 !important;
      }
    .form-group{
        margin-bottom:0;
        font-size:10px; 
        line-height:11px;
        position:relative; 
        svg{
            font-size: 18px;
            color: #000000;
            right: 15px;
            top: 11px;
            padding: 0;
            position: absolute;
            pointer-events: none;
            font-size: 24px;
            
          }
    }
    .form-control{
        height: initial;
        padding:14px;
    }
    h3{
        font-size:22px; 
        font-weight:400; 
        font-family:Arial; 
        text-align:center; 
        line-height:26px; 
        color: #fff; 
        margin-bottom: 18px; 
        padding-top:20px;
        padding-bottom:0; 
    }
    input{
        background: #fff;
        border: 1px solid #ddd;
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        padding: 13px 15px;
        border-radius: 0;
        width: 100%;
        line-height: normal;
        margin-bottom: 10px;
    }
    select{
        position:relative; 
        overflow:hidden; 
        width:100%; 
        font-size:12px;
        line-height:normal;
        border-radius:0;
        -webkit-appearance:none;
        appearance:none;
        &:after{
            content: "\f0d7";
            font-family: "FontAwesome";
        
            font-size: 18px;
            color: #000000;
            right: 15px;
            top: 11px;
            padding: 0;
            position: absolute;
            pointer-events: none;
            font-size: 24px;
        }
     

    }
    button{
        background-color:red; 
        color:#fff; 
        outline:none;
        border-radius: 0;
        border: 0;
    cursor: pointer;
    font-size: 16px;
    margin-top:10px;
    
    font-weight: 700;
    padding: 15px 45px;
    text-decoration: none;
    white-space: normal;
    width: 100%;
    text-transform: uppercase;
    line-height: 22px;
    display: inline-block;
    height: auto;
 
    &:hover{
        background-color: #f20000 !important;
        border:0 !important;
        box-shadow:none; 
     }
     
 
 
 .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
         background-color: #f20000 !important;
         border:0 !important;
         box-shadow:none; 
      }
      .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
         background-color: #f20000 !important;
         box-shadow:0; 
         border:none !important;
      }
    }
    p{
     //   padding:0 0 40px; 
        margin:0; 
    }
    .form-disclaimer{
        color:#fff; 
        font-size: 11px;
    line-height: 12px;
    display: block;
    margin-top: 10px;
    text-align: justify;
    font-family: Helvetica Neue
    }
   


}

@media screen and (max-width: 1196px) {
    .aside .bbb-block > div{
       // width:300px;
       width:100%;
       min-width:300px;
    }
    .sticky-form{
        width: 100%;
        min-width: 300px;
    
       // padding-bottom:17px;

    }
    .aside__scrollable{
        width: calc(35.4% - 15px);
     }
  
    .aside__scrollable .bbb-block{
        width:100%;
    }
    .bbb-block{
        min-width:300px;
     
    }

  
}
@media screen and (min-width:960px) and (max-width: 1100px) {
    .aside .bbb-block > div{
       // width:300px;
       width:100%;
       min-width:300px;
    }
    .sticky-form{
        width: 100%;
        min-width: 300px;
    
       // padding-bottom:17px;

    }
    .aside__scrollable{
        width: calc(33.4%);
     }
  
    .aside__scrollable .bbb-block{
        width:100%;
    }
  
}

@media screen and (min-width:767px) and (max-width: 960px) {
    
    .sticky-form h3{
        font-size:14px;
        line-height:18px;
        padding-top:10px;
        margin-bottom:8px;
    }
    .aside__scrollable{
       width: calc(35.4% - 15px);
    }
  
    .sticky-form input,.sticky-form select{
        font-size:10.6px;
    }
    .sticky-form button{
        font-size:11px;
        padding:10px 20px;
    }
    .sticky-form .form-disclaimer{
        font-size:10.5px;
    }
    .sticky-form{
       // width:100%;
    //  max-width:360px;
        padding-bottom:17px;
        height:450px;
        min-width:252px;

    }
    .aside .bbb-block > div{
       // width:240px;
       width:100%; 
       min-width:240px;
    }
    .App .main-image-container img{
        height:565px !important;
    }
    .aside .bbb-block{
        height:116px;
    }
    .aside__scrollable .bbb-block{
        width:100%;
    }
    .aside .bbb-block img{
        width:100%;
        max-width:252px;
        padding-left:15px;  
        padding-right:15px;

    }
    .aside .bbb-block > div{
        // width:300px;
        width:100%;
        min-width:252px;
     }
     .sticky-form{
         width: 100%;
         min-width: 252px;
     
        // padding-bottom:17px;
 
     }
   
     .bbb-block{
         min-width:252px;
      
     }
  

    }
@media screen and (max-width: 767px) {
.aside{
    display:none; 
}
.d-flex.justify-content-start.aside-col{
    display:none !important;
}
}
