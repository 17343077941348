
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Shadows+Into+Light+Two&display=swap&family=Lato:wght@900;400&display=swap&family=OpenSans:wght@600;700&display=swap');
.get-out-of-debt-container.container-fluid{
  padding-left:0;
  padding-right:0;
}
.get-out-of-debt-container {
  display: block;
  .form-page-2 .left-group{
        margin-top:-45px;
        
  }
.btn-primary{
  background-color: #C0050F;
  border: 1px solid #BD020C;
  border-radius: 5px;
  box-shadow: inset 0 1px 0 #f56c74;
  color: #fff;
  cursor: pointer;
  padding: 12px 20px 15px;
  width:80%;
  font-size: 24px;

  line-height: 1;
  font-weight: 600;
  text-align: center;
  display: block;
  margin: 0 auto;
  outline: none;
}
.site-inner{
  max-width:unset;
  .wrap{
    margin:0 auto;
    h2{
      font-weight:700;
    }
    h3{
      font-family:'Open Sans', Arial, sans-serif;
      font-weight:600;
    }
  }
  li{
    list-style-type: none;
  }
}
  .trust-pilot-logo {
    a {
      display: block;
      background: url(https://ww3.nationaldebtrelief.com/static/ndr_app/images/trust-pilot-logo-144.png) center no-repeat;
      width: 100px;
      height: 24px;
      background-size:contain;
      margin:auto;
     // width: 100%;
     // height: 13px;
      padding: 6px 0;
      border: none;
    }
  }
  .consumer-affairs-logo {
    a {
      display: block;
      background: url(https://ww3.nationaldebtrelief.com/static/ndr_app/images/consumer-affairs.png) center no-repeat;
      width: 100px;
      height: 24px;
      background-size:contain;
      margin:auto;
     // width: 100%;
     // height: 13px;
      padding: 6px 0;
      border: none;
    }
  }
  .site-header {
    padding: 25px 0;
    .wrap {
      max-width: 1095px;
    }
    .title-area {
      padding-top: 0;
    }
    .widget-area {
      margin-top: 5px;
      overflow: hidden;
      padding: 0 2% 0 0;
      width: auto;
      border-radius: 0;
      .gold-award {
        background: url(../assets/img/ttr2018-tiny.png) no-repeat;
        margin-right: 20px;
        margin-top: 5px;
        display: block;
        text-indent: -9999em;
        width: 46px;
        height: 74px;
        float: left;
      }
      .phone-number-icon {
        margin-right: 15px;
        float: left;
        font-size: 75px;
        color: #868686;
        i {
          display: block;
        }
      }
      .header-phone {
        margin: 6px 0 0 0;
        padding: 0;
        float: left;
        .call-now {
          font-size: 18px;
          font-weight: 400;
          color: #545454;
          line-height: 1;
          margin: 0 0 5px;
          padding: 0;
        }
        .phone-number {
          color: #190578;
          font-size: 40px;
          font-weight: 600;
          line-height: 1;
          display: block;
          text-decoration: none;
        }
      }
    }
  }
  .site-inner {
    .content-sidebar-wrap {
      margin: 0;
      max-width: 100%;
      display: block;
      padding: 0;
      .content {
        float: none;
        width: 100%;
        padding: 0;
        .section {
          .wrap {
            max-width: 1095px;
            .fancy {
              line-height: 0.5;
              text-align: center;
              margin-bottom: 40px;
              font-size: 42px;
              color: #6a6a6a;
              padding: 0;
              span {
                display: inline-block;
                position: relative;
                &:before, &:after {
                  content: " ";
                  position: absolute;
                  height: 3px;
                  border-bottom: 1px solid #CECECE;
                  border-top: 1px solid #CECECE;
                  top: 0;
                  width: 314px;
                }
                &:after {
                  left: 100%;
                  margin-left: 40px;
                  top: 5px;
                }
                &:before {
                  right: 100%;
                  margin-right: 40px;
                  top: 5px;
                }
              }
            }
            h3 {
              font-weight: 600;
              color: #03608F;
              font-size: 24px;
              text-align: center;
              margin: 0 0 25px;
              padding: 0;
            }
          }
        }
        .section-one {
          .form-page-1 {
            padding: 60px 0 0;
            background: url(../assets/img/get-out-of-debt-page-one-banner.jpeg) no-repeat;
            background-size: auto auto;
            min-height: 417px;
            background-size: 100%;
            &.show{
              display:block; 
              } 
              &.hide{
              display:none; 
              }
            .form-heading {
              display: block;
              h1 {
                font-size: 42px;
                font-weight: 700;
                text-align: center;
                padding: 0;
                margin-bottom: 60px;
                color: #fff;
              }
            }
            .select-wrapper {
              margin: -19px auto 30px;
              width: 50%;
              overflow: hidden;
              label {
                background: url(../assets/img/page-one-dropdown-label.png) no-repeat;
                height: 69px;
                display: block;
                margin: 0 auto -2.5%;
                font-size: 0;
              }
              select {
                font-size: 26px;
                line-height: 1;
                color: #777;
                box-shadow: inset 0 0 5px #bbb;
                width: 90%;
                margin: 0 auto;
                float: none;
                display: block;
                padding: 8px 18px 10px;
                background: url(../assets/img/page-one-dropdown-icon.png) no-repeat 98% center #fff;
                    // background-color: rgb(255, 255, 255);
                outline: none;
                cursor: pointer;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: none;
              }
            }
            .form-next-button {
              cursor: pointer;
              background-color: #C0050F;
              border: 1px solid #BD020C;
              border-radius: 5px;
              box-shadow: inset 0 1px 0 #F56C74;
              color: #fff;
              cursor: pointer;
              padding: 12px 20px 15px;
              width: 30%;
              font-size: 36px;
              line-height: 1;
              font-weight: 600;
              text-align: center;
              display: block;
              margin: 0 auto;
              outline: none;
              -webkit-transition: all 0.1s ease-in-out;
              -moz-transition: all 0.1s ease-in-out;
              -ms-transition: all 0.1s ease-in-out;
              -o-transition: all 0.1s ease-in-out;
              transition: all 0.1s ease-in-out;
            }
          }
          .mobile-form, .form-page-2 {
            display: none;
          }
          .form-page-2 {
            overflow: hidden;
            padding: 40px 0 0;
            background: url(../assets/img/get-out-of-debt-page-two-banner.jpeg) no-repeat;
            max-height: 600px;
            background-size: 100%;
            margin-bottom: 50px;
            &.show{
              display:block; 
              } 
              &.hide{
              display:none; 
              }
            input {
              font-size: 18px;
            }
            .get-started-form-submit {
              position: relative;
              margin: 0 56px 0 0;
              font-size: 24px;
              text-decoration: none;
              background: #A6040D;
              border-color: #A6040D;
              border: 1px solid #BD020C;
              border-radius: 5px;
              box-shadow: inset 0 1px 0 #F56C74;
              color: #fff;
              cursor: pointer;
              padding: 12px 20px 15px;
              line-height: 1;
              font-weight: 600;
              text-align: center;
              display: block;
              margin-top: 30px;
              margin-bottom: 20px;
              -webkit-transition: all 0.1s ease-in-out;
              -moz-transition: all 0.1s ease-in-out;
              -ms-transition: all 0.1s ease-in-out;
              -o-transition: all 0.1s ease-in-out;
              transition: all 0.1s ease-in-out;
            }
            h2 {
              margin-bottom: 75px;
              color: #fff;
              font-weight: 600;
              font-size: 42px;
              padding: 0;
            }
            h3 {
              margin-bottom: 14px;
              color: #cf2026;
              font-size: 36px;
              font-weight: 700;
            }
            .form-tagline {
              font-size: 17px;
              color: #545454;
              text-align: center;
              font-weight: 700;
              padding: 0;
              margin: 0 0 24px;
            }
            .red-arrow {
              top: 30px;
              left: -17px;
              position: absolute;
              background: url(../assets/img/page-two-arrow.png) no-repeat;
              width: 66px;
              height: 73px;
            }
            fieldset {
              border: none;
              margin: 0;
              padding: 0;
            }
            ul {
              margin: 0;
              padding: 0;
              .left-group {
                width: 53%;
                float: left;
              }
              .right-group {
                padding: 17px 22px 70px;
                position: relative;
                width: 443px;
                float: right;
                border: 1px solid #D7D7D7;
                background: #F3F3F3;
              }
            }
            .quick-estimate {
              p {
                padding: 0;
              }
              ul {
                li {
                  border-bottom: 1px solid #E9E9E9;
                  font-size: 18px;
                  line-height: 1.75;
                  padding-bottom: 13px;
                  margin-bottom: 10px;
                  .list-title {
                    font-weight: 700;
                    color: #292670;
                    font-size: 20px;
                    display: block;
                  }
                }
                .no-fees {
                  border-bottom: none;
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }
        }
        .section-two {
          padding: 60px 0 30px;
          background: #f5f5f5;
          ul {
            background: url(../assets/img/bbb-logo-alt1.png) no-repeat left center;
            padding-left: 235px;
            width: 570px;
            margin: 0 auto;
            li {
              margin-bottom: 12px;
              font-size: 16px;
              .fa {
                color: #03608f;
                font-size: 20px;
             
                position: relative;
                top: 1px;
                margin-right: 10px;
              }
            }
          }
        }
        .section-three {
          padding: 60px 0 20px;
          background: #fff;
          #reviews {
            // width: 65% !important;
            width: 70%;
            right: 0;
          //  padding: 0 5%;
            float:right;
            .slick-next {
              right: -10px;
              font-size:40px;
            }

            .slick-prev {
           //   left: 0px;
              font-size:40px;

            }
            .five-star.top {
              margin-left:0 ;
              text-align:left;  

            }
            .quote-content-wrap{
              p{ font-size:14px; 
               line-height:1.4; 
              }
           }
          }
          .reviews-overview {
          p{
            font-size: 14px;
            color: #6a6a6a;

          }
        }
        }
        .section-four {
          padding: 40px 0;
          background: #3b3b3b;
          overflow: hidden;
          .column-left {
            width: 25%;
            float: left;
            p {
              color: #B2B2B2;
              font-size: 14px;
              line-height: 2;
              margin: 0;
              padding: 0;
              font-weight: 400;
            }
          }
          .column-right {
            width: 75%;
            float: right;
            margin-top: 7px;
            ul {
              overflow: hidden;
              float: right;
              margin: 0;
              padding: 0;
              li {
                border-radius: 3px;
                float: left;
                background: #fff;
                padding: 3px;
                margin-left: 25px;
                a {
                  display: block;
                  text-indent: -9999em;
                }
              }
              .truste {
                background: none;
                padding: 0;
                margin-top: 12px;
                a {
                  width: 142px;
                  height: 45px;
                  background: url(../assets/img/truste-logo1.png) no-repeat;
                }
              }
              .norton {
                margin-top: 3px;
                a {
                  width: 116px;
                  height: 57px;
                  background: url(../assets/img/norton-logo1.png) no-repeat #fff;
                }
              }
              .mcafee {
                a {
                  width: 127px;
                  height: 63px;
                  background: url(../assets/img/mcafee-secure-logo1.png) no-repeat #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    background: #494949;
    padding: 35px 0;
    color: #c9c9c9;
    font-size: 14px;
    ul {
      text-align: center;
      margin-bottom: 15px;
      padding: 0;
      li {
        padding: 0 7px 0 10px;
        display: inline-block;
        float: none;
        border-left: 1px solid #c9c9c9;
        a {
          color: #c9c9c9;
          font-weight: 400;
          font-size: 14px;
          font-size: 14px;
          text-decoration: none;
        }
      }
      li:first-child {
        border-left: none;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-1 .select-wrapper select{
  font-size: 16px;
  padding: 9px 11px 10px;
  background-position: 95% center;
}

      .get-out-of-debt-container {
        .site-header {
          .wrap {
            .widget-area {
              .header-phone {
                margin-top: 0;
                float: right;
                margin-right: 30px;
                .phone-number {
                  font-size: 16px;
                }
              }
            }
          }
        }
        .site-inner {
          .content-sidebar-wrap {
            .content {
              .section {
                .wrap {
                  .fancy {
                    font-size: 24px;
                    line-height: 1;
                  }
                }
              }
              .section-one {
            
                  .form-page-1 {
                    .form-heading {
                      h1 {
                        font-size: 28px;
                        padding-right: 10px;
                        padding-left: 10px;
                      }
                    }
                    .select-wrapper {
                      label {
                        width: 96%;
                        background-size: 75%;
                        margin: 0 0 -6%;
                      }
                    
                    }
                  }
                  .form-page-2 {
                    h2 {
                      margin-bottom: 41px;
                      font-size: 24px;
                    }
                    .red-arrow {
                      display: none;
                    }
                  }
                
              }
              .section-two {
                .wrap {
                  ul {
                    line-height: 1.7;
                    li {
                      margin-left: 5%;
                    }
                  }
                }
              }
            }
          }
        }
      }
   
}

@media screen and (max-width: 767px) {
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-1 .form-heading h1{
    font-size: 30px;
                    font-weight: 600;
                    line-height: 1.4;
                    padding: 0 7%;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-two ul{
    display:flex;
    flex-direction:column;
    width:100%;
    padding-left:0;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-two ul{
    width: 100%;
    padding: 110px 0 0;
    background-position: center top;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section .wrap{
    width:100%;
    max-width:unset;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-1 .select-wrapper{
    width:100%;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-1 .form-next-button{
    width:83%;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section .wrap h3{
   
      font-size: 20px;
      line-height:28px;
    
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-2 ul .right-group{
    width:100%;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-2{
    overflow:hidden;
    max-height:unset;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-2 ul .left-group{
    width:100%;
    margin:auto;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-2{
    padding-top:15px;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-2 .quick-estimate p{
    display:none;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-one .form-page-2 .quick-estimate ul li .list-title{
    text-align:center;
  }
  .reviews-overview{
    width: 100%;
    margin-bottom: 35px;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-three #reviews .slick-prev{
left:-50px;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-three #reviews .slick-next{
    right:-30px;
  }
  .get-out-of-debt-container .site-inner .content-sidebar-wrap .content .section-three #reviews{
    float:none;
    clear:both;
    margin:0 auto; 
    width:75% !important;
  }
    .get-out-of-debt-container {
      .site-header {
        padding: 10px 0;
        .wrap {
          .widget-area {
            .phone-number-icon {
              display: none;
            }
            .header-phone {
              margin-top: 3px;
              .call-now {
                display: none;
              }
              .phone-number {
                font-size: 24px;
                margin-top: 10px;
              }
            }
          }
        }
      
        
      .site-inner {
        .content-sidebar-wrap {
          .content {
            .section {
              .wrap {
                padding: 0 4%;
                .fancy {
                  span {
                    &:before, &:after {
                      display: none;
                    }
                  }
                }
              }
            }
            .section-one {
              .form-page-1 {
                padding: 30px 0 0;
                background-position: center top;
                .form-heading {
                  h1 {
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 1.4;
                    padding: 0 7%;
                  }
                }
                .form-next-button {
                  width: 83%;
                }
              }
            }
            .section-two {
              .wrap {
                ul {
                  width: 100%;
                  padding: 110px 0 0;
                  background-position: center top;
                  li {
                    margin-left: 15%;
                  }
                }
              }
            }
            .section-four {
              .column-left {
                width: 100%;
                float: none;
                margin-bottom: 30px;
                p {
                  text-align: center;
                }
              }
              .column-right {
                width: 100%;
                float: none;
                margin-top: 0;
                ul {
                  float: none;
                  width: 100%;
                  text-align: center;
                  li {
                    display: inline-block;
                    float: none;
                    margin: 0 65px 30px;
                  }
                  .truste {
                    margin-top: 0;
                  }
                  .norton {
                    margin-top: 0;
                  }
                  .mcafee {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
      .footer {
        .wrap {
          padding: 0 4%;
          ul {
            li {
              border: none;
              padding: 0 10px 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .flex-wrap {
    .get-out-of-debt-container {
      .site-header {
        .widget-area {
          margin-top: 0;
          .gold-award {
            display: none;
          }
          .phone-number-icon {
            font-size: 63px;
          }
          .header-phone {
            .call-now {
              font-size: 16px;
            }
            .phone-number {
              font-size: 30px;
            }
          }
        }
      }
      .site-inner {
        .content-sidebar-wrap {
          .content {
            .section {
              .wrap {
                .fancy {
                  margin-bottom: 30px;
                  font-size: 32px;
                  span {
                    &:after, &:before {
                      width: 130px;
                    }
                  }
                }
                h3 {
                  font-size: 20px;
                }
              }
            }
            .section-one {
              .form-page-1 {
                background-size: inherit;
                .form-heading {
                  h1 {
                    font-size: 32px;
                  }
                }
                .select-wrapper {
                  width: 95%;
                  label {
  
                  }
                }
              }
              .form-page-2 {
                max-height: inherit;
                .quick-estimate {
                  .list-title {
                    text-align: center;
                  }
                }
                .left-group {
                  width: 100%;
                  margin-bottom: 30px;
                  width: 97.5%;
                  p {
                    display: none;
                  }
                }
                .right-group {
                  padding: 17px 22px 0;
                  margin-bottom: 20px;
                  width: 97.5%;
                }
              }
            }
            .section-three {
              .reviews-flex {
                flex-direction: column;
                #reviews {
                  width: 100% !important;
                  padding: 0 5%;
                  // margin-left: 5%;
                  .slick-prev {
                    left: -22px;
                  }
               
                }
              
                .quote-content-wrap{
                   p{ font-size:14px; 
                    line-height:1.4; 
                   }
                }
               
              }
              .reviews-overview {
                width: 100%;
                margin-bottom: 35px;
                p{
                  font-size: 14px;
   
    color: #6a6a6a;
   
                }
              }


            }
          }
        }
      }
    }
  }
}
.get-out-of-debt-container .header.row{
  margin-left:0;
  margin-right:0;
}
.get-out-of-debt-container .main-footer.row{
  margin-left:0;
  margin-right:0;
}
.get-out-of-debt-container .footer.row{
  margin-left:0;
  margin-right:0;
}
.get-out-of-debt-container .main-footer.row{
  margin-left:0;
  margin-right:0;
}
@media screen and (max-width: 1200px) {


  .get-out-of-debt-container {
    .site-inner {
      .content-sidebar-wrap {
        .content {
          .section {
            .wrap {
              padding: 0 2%;
              .fancy {
                span {
                  &:after, &:before {
                    width: 228px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .get-out-of-debt-container {
    // .site-header {
    //   .widget-area {
    //     margin-top: 0;
    //     .gold-award {
    //       display: none;
    //     }
    //     .phone-number-icon {
    //       font-size: 6.3rem;
    //     }
    //     .header-phone {
    //       .call-now {
    //         font-size: 1.6rem;
    //       }
    //       .phone-number {
    //         font-size: 3rem;
    //       }
    //     }
    //   }
    // }
    .site-inner {
      .content-sidebar-wrap {
        .content {
          .section {
          }
          .section-one {
            .form-page-1, .form-page-2 {
              background-size: inherit;
            }
          }
        }
      }
    }
  }
}
