
@media screen and (max-width: 479px) {
  .after-entry-reviews {
    .top {
      margin-left: -10%;
      }
    .reviews-overview {
      .bottom {
        p {
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .after-entry-reviews {
    h2 {
      font-size: 26px !important;
    }
    .reviews-overview {
      width: 100%;
      float: none;
      overflow: hidden;
      border-bottom: 1px solid #d1daeb;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      text-align: left;
      line-height: 1.55;
      .trust-pilot {
        margin: 0 0 30px;
      }
      .top {

        h3 {
          font-size: 18px;
          font-weight: 700;
          margin: 0 0 -5px;
          text-align: center;
        }
        .fa-star {
          font-size: 13px;
          display: inline-block;
          margin-right: -2px;
          float: none;
        }
      }
    }
  }
}

.after-entry-reviews {
  margin-right: -100vw;
  background: #f4f7fa;
  margin-left: -100vw;
  padding: 30px 100vw;
  .wrap {
    width: 100% !important;
  }

  #reviews {
    width: 60%;
    position: relative;

  }

  h2 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid #d1daeb;
    padding-bottom: 30px;
    margin: 0 0 25px;
  }
  h3 {
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 10px;
    padding: 0;
  }
}

.reviews-overview {
  width: 30%;
  float: left;
  text-align: center;
  line-height: 1.8;
  a.trust-pilot {
    display:none; 
 
    margin: 0 auto 30px;
   // display: block;
    text-indent: -9999em;
    width: 106px;
    height: 12px;
    border: none;
   background-repeat:no-repeat; 
  }
  p {
    font-size: 17px;
    margin-bottom: 10px;
    color: #9c9c9c;
    padding: 0;
  }
}

.five-star {
  margin-bottom: 10px;
  overflow: hidden;
  .fa {
    
    color: #fff;
    background: #007E4E;
    margin-bottom: 0;
    color: #fff;
    padding: 3px;
    font-size: 13px;
   
    border-radius: 2px;
    display: block;
    float: left;
    margin-right: 1px;

  }
}

.five-star.top {
  width: 195px;
  float: none;
  text-align: center;
  margin: 0 auto 10px;
  .fa {
    font-size: 28px;
  }
}

#reviews {
  .slick-prev, .slick-next {
    display: block;
    position: absolute;
    top: 60px;
    color: #e5e8eb;
    background: none;
    padding: 0;
    border: none;
    font-size: 5px;
    box-shadow: none;
    width: auto;
    height: auto;
  &:focus {
    color: #999;
  }
  span {
    text-indent: -9999em;
    display: inline-block;
  }
}
  .fa-star:before {

    content: "\f005";
  }
  .slick-prev {
    left: -55px;
    &:before {
      content: none;
    }
  }

  .slick-next {
    right: -35px;
    &:before {
      content: none;
    }
  }
}

@media screen and (max-width: 1280px) and (min-width: 1070px) {
  #reviews {
    width: 63% !important;
  }
}

@media screen and (max-width: 1070px) and (min-width: 1016px) {
  #reviews {
    width: 68% !important;
  }
}

@media screen and (max-width: 1016px) and (min-width: 1000px) {
  #reviews {
    width: 50% !important;
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  #reviews {
    width: 60% !important;
  }
}

@media screen and (max-width: 1359px) {

  .slick-slide {
    width: 252px;
  }
  #reviews {
    right: -30px;
  }
  .slick-track {
    width: 3276px;
    transform: translate3d(-504px, 0px, 0px);
  }
}

@media screen and (max-width: 1198px) {
  .slick-slide {
    width: 209px;
  }
  .slick-track {
    width: 2717px;
    transform: translate3d(-418px, 0px, 0px);
  }
}

@media screen and (max-width: 1000px) {
  .slick-slide {
    width: 516px;
  }
  .slick-track {
    width: 6717px;
  }

}

@media screen and (max-width: 768px) {
   .reviews-flex {
     flex-direction: column;
   }
   #reviews {
    width: 80% !important;
  }

 }

.quote-content-wrap p {
  line-height: 1.4;
  margin-bottom: 5px;
  padding: 0;
  font-family:'Open Sans', Arial, Helvetica, sans-serif;

}

.quote-title {
  font-weight: 700;
  font-size: 14px;
  font-family:'Open Sans', Arial, Helvetica, sans-serif;


}

.quote-body {
  font-size: 14px;
  font-family:'Open Sans', Arial, Helvetica, sans-serif;

  
}

.quote-author {
  clear: both;
  line-height: 13px;
  font-weight: 600;
  font-size: 14px;
 font-family:'Open Sans', Arial, Helvetica, sans-serif;
  font-style: italic;
}

.reviews-flex {
  display: flex;
}


.quote-content-wrap {
  width: 90%;
}

@media screen and (max-width: 388px) {
  .after-entry-reviews {
    .top {
      margin: auto;
    }
    .reviews-overview {
      flex-direction: column;
      p {
        text-align: center !important;
      }
      .trust-pilot {
        margin: 0 auto 30px;
      }
    }
  }
}
//
//.carousel-nav-left, .carousel-nav-right {
//  bottom: 2em !important;
//  color: #e5e8eb !important;
//  background: none !important;
//  font-size: 5rem !important;
//  .fa-chevron-left {
//    padding-right: 25px;
//  }
//  .fa-chevron-right {
//    padding-left: 65px;
//  }
//}
//
//@media screen and (max-width: 1425px) {
//  .is-background {
//    width: 80% !important;
//  }
//}
//
//@media screen and (max-width: 1090px) {
//  .carousel-nav-left, .carousel-nav-right {
//    .fa-chevron-left {
//      padding-right: 50px;
//    }
//    .fa-chevron-right {
//      padding-left: 50px;
//    }
//  }
//}
//
//@media screen and (max-width: 452px) {
//  .carousel-nav-left, .carousel-nav-right {
//    //bottom: 50px !important;
//    .fa-chevron-left {
//      padding-right: 70px;
//    }
//    .fa-chevron-right {
//      padding-left: 70px;
//    }
//  }
//}
