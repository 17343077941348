@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Shadows+Into+Light+Two&display=swap&family=Lato:wght@300;900&display=swap");

.site-inner .video-phone h4 {
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #555;
  margin-bottom: 15px;
  a {
    text-decoration: none;
  }
}

.free-debt-consolidation-quote-header {
  border: none;
  .wrap {
    max-width: 1080px;
    margin: 0 auto;
  }
}
.free-debt-consolidation-quote-aside {
  padding-left: 0;
  padding-right: 0;
}
.freedebtloanquotemobileform {
  display: none;
}

@media screen and (max-width: 479px) {
  .flex-wrap {
    .free-debt-consolidation-quote {
      .cut-up-credit-card-debt {
        background: none;
        padding: 0 0 20px;
        li {
          padding: 0px 0 0 25px;
        }
      }
      .money-back-guarantee {
        width: 97%;
        background-position: center 15px;
        padding-top: 190px;
        h4 {
          padding-left: 20px;
          font-size: 16px;
        }
        p {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .aligncenter {
        width: 90%;
      }
    }
    .free-debt-consolidation-quote-footer {
      .copyright {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
    .freedebtconsolidationquote .applyLink-col{
        display:none;
        }
  .free-debt-consolidation-quote-header {
    .widget-area {
      display: none;
    }
  }
  .free-debt-consolidation-quote .content-sidebar-wrap {
    padding: 0 !important;
  }
  .freedebtloanquotemobileform {
    display: block;
  }
  .freedebtloanquotemobileform {
    .form-control.get-started-form-input {
        padding:.375rem .75rem;
    }
    .get-started-form-submit {
      font-weight: 400;
      //  font-family: Lato;

      background-image: -webkit-linear-gradient(
        bottom,
        rgb(189, 54, 47) 0%,
        rgb(238, 95, 91) 73%,
        rgb(238, 95, 91) 100%
      ) !important;
      border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      color: #ffffff;

      width: 100% !important;
      margin-left: 0px;
      font-size: 21px !important;

      text-transform: uppercase;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.75);
      font-size: 22px;
      &:hover {
        background-image: -webkit-linear-gradient(
          bottom,
          rgb(81, 163, 81) 0%,
          rgb(81, 163, 81) 45%,
          rgb(98, 196, 98) 100%
        ) !important;
        background-color: #51a351 !important;
      }
    }

    .get-started-form {
      width: 100%;
      margin-bottom: 15px;
      max-width: 100%;

      padding: 0;
      .get-started-form-input {
        width: 68%;
      }

      .select-wrapper:after {
        top: 5px;
      }
      input,
      select {
        width: 100%;
        font-size: 1.6rem;
        padding: 5px;
        border-radius: 0px;
        border: 1px solid #cbccce;
        background: #fbfdfc;
        margin-bottom: 15px;
        line-height: 36px;
      }

      button {
        font-size: 2.1rem;
        text-transform: uppercase;
        width: 100%;
        display: block;
        margin: 0 auto;
        background-color: #bd362f;
        padding: 10px 0;
        border-radius: 3px;
        background: -moz-linear-gradient(
          bottom,
          rgb(189, 54, 47) 0%,
          rgb(238, 95, 91) 73%,
          rgb(238, 95, 91) 100%
        );
        &:hover {
          color: #ffffff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, rgb(81, 163, 81)),
            color-stop(0.45, rgb(81, 163, 81)),
            color-stop(1, rgb(98, 196, 98))
          ) !important;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
            rgba(0, 0, 0, 0.25);

          .fa {
            background-color: #079031;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .flex-wrap {
    .free-debt-consolidation-quote {
      background: none;
      .mobile-only-form {
        display: block;
      }
    }
  }
  .free-debt-consolidation-quote-footer {
    .copyright {
      width: 100%;
      text-align: center;
      padding-bottom: 0;
      padding-top: 20px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .free-debt-consolidation-quote-header {
    .widget {
      .icon-wrap {
        .fa {
          font-size: 24px;
        }
      }
    }
  }
  .free-debt-consolidation-quote {
    .content-sidebar-wrap {
      max-width: 100%;
      padding: 30px 2%;
    }
  }
}

.free-debt-consolidation-quote {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
  border-bottom: 1px solid #dee4ed;
  h4,
  h2 {
    font-weight: 700;
    margin: 0;
  }
  .mobile-only-form {
    display: none;
    form {
      width: 100%;
      margin-bottom: 15px;
      max-width: 100%;
      padding: 0;
      background: transparent;
      float: none;
      h3 {
        padding-left: 0;
        margin-bottom: 20px;
        font-size: 21px;
        text-align: left;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 36px;
        padding: 0;
      }
      input,
      select {
        width: 100%;
        font-size: 16px;
        padding: 5px;
        border-radius: 0px;
        border: 1px solid #cbccce;
        background: #fbfdfc;
        margin-bottom: 15px;
      }
      button {
        font-size: 21px;
        text-transform: uppercase;
        width: 100%;
        display: block;
        margin: 0 auto;
        background-color: #bd362f;
        padding: 10px 0;
        border-radius: 3px;
        background-image: -moz-linear-gradient(
          bottom,
          #bd362f 0%,
          #ee5f5b 73%,
          #ee5f5b 100%
        );
      }
    }
    h4 {
      font-size: 3.2rem;
      line-height: 32px;
      color: #e36d0d;
      text-shadow: 1px 1px 1px #000;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .entry-content {
    padding: 0;
  }
  .content {
    padding: 0;
  }
  .content-sidebar-wrap {
    overflow: hidden;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    padding: 45px 0 30px;
  }
  .cut-up-credit-card-debt {
    background: url(../assets/img/woman-cutting-up-credit-card.jpeg) right 0px
      no-repeat;
    padding-top: 30px;
    padding-bottom: 25px;
    li {
      padding-right: 250px;
      padding-left: 25px;
      list-style: none;
      background: url(../assets/img/green-check.png) 0 3px no-repeat;
      margin: 0 0 20px 0;
      font-size: 15px;
      font-size: 15px;

      border: none;
      line-height: 24px;
      p {
        padding: 0;
      }
    }
  }
  h4 {
    font-size: 18px;
    text-align: center;
    color: #555;
    margin-bottom: 15px;
    line-height: 24px;
    padding: 0;
  }
  h3 {
    font-size: 30px;
    line-height: 36px;
    color: #18027a;
    margin-bottom: 10px;
    padding: 0;
  }
  h2 {
    font-size: 30px;
    text-align: center;
    color: #da4f49;
    margin-bottom: 25px;
    line-height: 30px;
    padding: 0;
  }
  .embed-container {
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
  .money-back-guarantee {
    background: url(../assets/img/money-back-guarantee.png) 20px 50% #fff9cd
      no-repeat;
    border-top: 1px solid #e0d89d;
    border-bottom: 1px solid #e0d89d;
    padding: 25px 0px;
    margin-bottom: 40px;
    h4 {
      margin-bottom: 0px;
      font-size: 20px;
      padding-left: 200px;
      padding-right: 20px;
      text-align: left;
    }
    p {
      padding-left: 200px;
      padding-right: 20px;
      margin: 0;
      padding-bottom: 0;
      line-height: 24px;
    }
  }
  .testimonial {
    background: #fafafa;
    border: 1px solid #eee;
    padding: 20px;
    margin-bottom: 20px;
    p {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 24px;
      padding: 0;
    }
    .quote {
      font-style: italic;
      margin-bottom: 15px;
    }
    .cite {
      margin-bottom: 0px;
      text-align: right;
    }
  }
  .testimonial-image {
    width: 100px;
    height: 110px;
    display: block;
    float: left;
    margin-right: 8px;
    margin-top: 4px;
    font-size: 16px;
    line-height: 1.6;
  }
  .testimonial-image-one {
    background: url(../assets/img/nickolas-h.jpeg) no-repeat;
  }
  .testimonial-image-two {
    background: url(../assets/img/carol-d.jpeg) no-repeat;
  }
  .testimonial-image-three {
    background: url(../assets/img/bonnie-l.jpeg) no-repeat;
  }
  .testimonial-image-four {
    background: url(../assets/img/michelle-m.jpeg) no-repeat;
  }
  .testimonial-image-five {
    background: url(../assets/img/david-c.jpeg) no-repeat;
  }
  .testimonial-image-six {
    background: url(../assets/img/martha-s.jpeg) no-repeat;
  }
  .testimonial-image-seven {
    background: url(../assets/img/marcia-h.jpeg) no-repeat;
  }
  .aligncenter {
    margin: 0 auto 24px;
    display: block;
  }
  aside {
    display: inline;
    font-size: 16px;
    padding-bottom: 30px;
    background: none;
    float: right;
    width: 340px;
    //padding-right: 20px;
    position: relative;
    .big-red-down-arrow {
      background: url(../assets/img/big-red-down-arrow.png) no-repeat;
      width: 145px;
      height: 153px;
      margin: 0 auto;
    }
    .get-started-form {
      width: 100%;
      margin-bottom: 40px;
      max-width: 100%;
      padding: 0;
      .select-wrapper:after {
        top: 5px;
      }
      input,
      select {
        width: 100%;
        font-size: 16px;
        padding: 5px;
        border-radius: 0px;
        border: 1px solid #cbccce;
        background: #fbfdfc;
        margin-bottom: 15px;
      }
      button {
        font-size: 21px;
        text-transform: uppercase;
        width: 100%;
        display: block;
        margin: 0 auto;
        background-color: #bd362f;
        padding: 10px 0;
        border-radius: 3px;
        background-image: -moz-linear-gradient(
          bottom,
          rgb(189, 54, 47) 0%,
          rgb(238, 95, 91) 73%,
          rgb(238, 95, 91) 100%
        );
        &:hover {
          color: #ffffff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, rgb(81, 163, 81)),
            color-stop(0.45, rgb(81, 163, 81)),
            color-stop(1, rgb(98, 196, 98))
          ) !important;
          border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
            rgba(0, 0, 0, 0.25);
        }
      }
    }
    .bbb-logo {
      margin-bottom: 40px;
      img {
        margin: 0 auto;
        display: block;
      }
    }
    h3 {
      padding-left: 0;
      margin-bottom: 20px;
      font-size: 21px;
      text-align: center;
      color: #18027a;
      // text-transform: uppercase;
      font-weight: 400;
    }
    .proven-results-list {
      max-width: 225px;
      margin: 0 auto;
      padding: 0;
      li {
        padding: 0 0 10px;
        background: none;
        border-bottom: 3px solid #fafafa;
        margin: 0 0 20px 0;
        font-size: 15px;
        text-indent: 0;
        text-align: center;
        img {
          margin-bottom: 30px;
        }
        p {
          text-align: center;
          font-weight: bold;
          margin-bottom: 0px;
          line-height: 24px;
          padding: 0;
        }
        .red {
          color: #ff0000;
        }
        .green {
          color: #3f9a5c;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
}

.db {
  background: url(../assets/img/trustpilot-logo-288.png) no-repeat;
  background-size: auto auto;
  margin: 21px 0 0 0;
  width: 244px;
  height: 72px;
  background-size: 100%;
  display: block;
  float: left;
  text-indent: -9999em;
}

.freedebtconsolidationquote .row {
  margin-left: 0;
  margin-right: 0;
}
.freedebtconsolidationquote .navigation-wrapper {
  max-width: 1080px;
  margin: auto;
}
.freedebtconsolidationquote {
  .fixed-mobile-footer {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
    padding: 0;
    text-align: center;
    border-top: 2px solid #ddd;
    .button {
      background-color: #18027a;
      text-transform: uppercase;
      border: none;
      border-radius: 3px;
      box-shadow: none;
      color: #fff;
      cursor: pointer;
      padding: 7px 0;
      font-weight: 700;
      width: 100%;
      font-size: 24px;
      border-radius: 0;
      display: inline-block;
    }
  }
  .fixed-mobile-footer a.button {
    padding: 7px 0;
    font-weight: 700;
    width: 100%;
    font-size: 24px;
    border-radius: 0;
    text-decoration: none;
  }
}
