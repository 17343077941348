
.navigation {
    background-color: #ffffff !important;
    padding: .5rem .5rem .5rem .5rem  !important;
    margin: 0 auto;
    display: flex;
    justify-content: space-between !important;
    .header-phone {
        color: #000;
        padding: 10px 0 0 10px;
      //  margin: 20px;
      margin:0 auto;
        white-space: nowrap;
        font-size: 30px;
        text-decoration: none;
        white-space: nowrap;
        font-weight:900;
        line-height:36px;
        display:block;
        text-align:center;
        font-family:Helvetica Neue,HelveticaNeue-Light,Helvetica Neue Light,Helvetica,Arial,Lucida Grande,sans-serif;
        svg{
            transform:rotate(90deg);
            margin-right:10px;
            vertical-align: baseline;
        }
        a{
            &:hover {
                color: #ff1300;;
                text-decoration: none;
            }
        }
    
    }
  
}
@media screen and (max-width: 960px) {
    .navigation .header-phone{
      //  width:200px;
        height:46px;
        font-size:16px;
        padding-left:0;
        margin-right:0;
        line-height:24px;
     
    }
}

@media screen and (max-width: 687px) {
    .navigation {
        flex-wrap: nowrap !important;
        padding: 10px !important;
   
       img  {
           width: 170px;
       }
    }

    .navigation .header-phone{
        width:150px;
    }
}