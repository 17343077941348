.ca-reviews .ca-heading {background: #176199;color: #fff;padding: 5px;font-size: 14px !important; font-weight:bold; text-align: center;margin-bottom: 15px;}
        .ca-reviews h2,
        .apply-new-2 .gform_wrapper .ca-reviews h2 {font-size: 30px;padding: 0;border: none;background: none !important;margin: 0 0 5px;width: auto;position: static;text-transform: uppercase; text-align: center;}
        .ca-reviews .based-on {text-align: center;}
        .ca-reviews .five-star {margin: 0 0 5px 0;text-align: center;width: fit-content; display:flex;flex-direction:row; margin:0 auto;}
        .ca-reviews .svg-inline--fa{height:13px;}
        .ca-reviews .five-star .fa-star {font-size: 22px; float:left; clear:both;}

        .right-box-core-trustpilot,
.mobile-ca-display .right-box-core-trustpilot {
	padding: 10px 10px 0px;
	height: 490px;
	overflow-y: scroll;
	overflow-x: hidden;
	background: #fff;
}
.ca-reviews .five-star .fa {
	background: #079031;
	color: #fff;
	padding: 3px;
font-size:20px;
	border-radius: 2px;
	margin-left: 0px;
}
 .ca-reviews .five-star svg {
	background: #079031;
	color: #fff;
	padding: 3px;
	height:27px;
	
	border-radius: 2px;
	margin-left: 0px;
}
.consumer-affairs a {
	display: block;
	background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2017/10/consumer-affairs.png) center no-repeat #fff;
	width: 100%;
	height: 32px;
	padding: 6px 0;
	border-top: 1px solid #ccc;
	text-indent: -9999em;
}
.page-one .trust-pilot-block {
	height: auto;
	width: 220px;
	float: left;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
	position: absolute;
	right: 0px;
    top:40px;
}
.thankyou .right-box-core-trustpilot {
	height:auto;
	overflow:auto;
	padding:0;
	background:none;
	}

.trust-pilot-block .trust-pilot-wrap a {
	display: block;
	height: 522px;
	width: 200px;
	background: url(https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/trust-pilot.png) no-repeat;
}
 .trust-pilot-block .right-box-core-trustpilot,
 .right-box-core-trustpilot {
	padding: 10px 10px 0px;
	height: 490px;
	overflow-y: scroll;
	overflow-x: hidden;
	background: #fff;
    .based-on{
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 24px;
        padding: 0;
        font-family: Arial, sans-serif;
    }
  .ca-review{
    p {
        font-size: 16px;
       
        line-height: 24px;
        margin: 0 0 24px;
        padding: 0;
        font-family: Arial, sans-serif;
    }
  }
}
