.modal-content {
  background-color: transparent !important;
  border: none !important;
  z-index: 1;
}

.close {
  border: 5px solid #ffffff;
  opacity: .8 !important;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 99999999;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-video-container  {
    position: relative;
    padding-top: 66.25%; 
    margin-top: 50px;
    .video-player {
      position: absolute;
      padding: 10px;
      top: 0;
      left: 0;
    }
  }