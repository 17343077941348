.trust-icon-container {
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-radius: 100px;
  height: 80%;
  width: 100%;
  margin: 40px 0;
  padding: 30px 10px;
  box-shadow: 16.1px 19.2px 35px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border-bottom: none !important; 
    .trust-logo-content {
      display: flex;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
  }
}


@media screen and (max-width: 687px) {
  .trust-icon-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-template-rows: 50%;
    border-radius: 0;
    width: 100%;
    .col_1 {
      grid-column-start: 1;
      grid-column-end: 3;
      padding-bottom: 20px;
    }
    .col_2 {
      grid-column-start: 3;
      grid-column-end: 6;
      padding-bottom: 20px;
    }
    .col_3 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .trust-logo-content {
      display: flex;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}