@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.unbounceheader .applyLink p {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: rgb(24, 2, 122);
  font-style: normal;
  margin-bottom:0;

}

.unbounceheader .applyLink .header-phone{
    color:  #18027a;
    padding:0;
    
  
  }
  .unbounce .row{
      margin:0;
  }
  .unbounceheader .applyLink{
      display:block;
  }
  .unbounceheader .applyPhonemobile{
      display:none;
  }
  @media screen and (max-width: 767px){
      .unbounceheader{
          .navbar-brand{
              padding-left:30px;
          }
      }
    .unbounceheader .applyPhonemobile{
        display:block;
        width:fit-content;
        margin:auto;
    }
    .unbounceheader .applyLink{
        display:none;
    }
    .unbounceheader .applyLinkmobile{
        display:block;
        width:fit-content;
        margin: auto;
    }
   .unbounceheader .applyLinkmobile img{
        width:97px;
        height:43px;
    }
    .apply-new-2 .applyLinkmobile img{
      width:71px;
      margin:auto;
    }
    .apply-new-2 .site-header .widget-area{
        width:fit-content;
    }
   .apply-new-2 .navigation .header-phone{
        color:#18027a;
        font-size: 28px;
        line-height: 34px;
        font-weight: 900;
        font-family: Lato;
        font-style: normal;
        margin:auto;
        width:fit-content;

    }
    .unbounceheader{
        display:flex;
        flex-direction: column;
    }
  }
