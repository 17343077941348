.lp-pob-box {
  display: block;
  background: rgba(241, 244, 249, 1);
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 20px;

  z-index: 2;
  width: 705px;
  height: fit-content;
  margin: 0 auto;

  padding-bottom: 45px;
}

.lp-pom-text {
  display: block;
  background: rgba(255, 255, 255, 0);
  border-style: none;
  border-radius: 0px;

  z-index: 3;
}
.title_secondary {
  background: url(../assets/img/blue-sep.png) center bottom no-repeat !important;
  padding: 0 0 10px;
  margin: 0px 0 20px;
  font-family: "Roboto", sans-serif;
  color: #383838;
  font-weight: 400;
  font-size: 18px;

  padding-top:15px;

  text-align: center;
}
.lp-pom-text {
  display: block;
  background: rgba(255, 255, 255, 0);
  border-style: none;
  border-radius: 0px;

  z-index: 3;
  width: 100%;

  margin: auto;
 
}
.lp-pom-text.main-text {
  padding-left: 90px;
  padding-right: 90px;
  padding-top:15px;
}

.lp-pom-button {
  display: block;
  border-style: none;
  border-radius: 20px;

  z-index: 4;
  width: fit-content;
  height: 45px;

  background: rgba(212, 0, 0, 1);
  box-shadow: none;
  text-shadow: none;
  color: #fff;
  margin: auto;
  font-size: 24px;
  line-height: 45px;
  font-weight: 900;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Lato;
  font-style: normal;
  text-align: center;
  background-repeat: no-repeat;
  cursor: pointer;
  text-decoration: none;
  &:hover {
      color:#fff;
      text-decoration:none;
      background:rgba(201,0,0,1);
  }
}
.lp-pob-box h1 {
  font-size: 48px;
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  color: rgb(24, 2, 122);
  text-align: center; line-height: 58px;
}

@media only screen and (max-width: 600px) {
  .lp-pob-box {
    width: 318px;
    padding-left:10px;
    padding-right:10px;
    h1 {
      font-size: 24px;
      line-height: 28px;

      font-family: Roboto;
    font-weight: 700;
    font-style: normal;
    color: rgb(24, 2, 122);
    margin-bottom:0;
    padding-bottom:10px;
    
    }
  }
  .title_secondary {
    background: none !important;
    line-height:32px;
  }
  .lp-pom-text.main-text {
    padding-left: 0px;
    padding-right:0px;
    padding-top: 0px;
    font-size:16px;
}
.lp-pom-button {
height:fit-content;
line-height:29px;
padding-top:5px;
padding-bottom:5px;
span{
font-size:24px;
}
}
}
