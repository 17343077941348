.getoutofdebtloanheader{
 .header-phone {
   a {
        color: #190578;
        font-size: 40px;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1;
        display: block;
    }
 }
.applyLink-col{
    display:flex;
    flex-direction: row;
    .applyWidgetRight{
display:flex;
flex-direction:row;

    }
    svg{
        transform:rotate(0deg);
        height:75px;
        width:32px;
        
        }
}
.gold-award {
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2021/01/TopTenReviews-2021-Best-Overall.png) no-repeat;
    width:46px;
    height:74px;
    margin-right:15px;
}
p.call-now {
    font-size: 18px;
  
    font-weight: 400;
    color: #545454;
    line-height: 1;
    margin: 0 0 5px;
}

}
.get-out-of-debt-container{
    .navigation .header-phone{
       
            color: #190578;
            font-size: 40px;
          padding:0;
            font-weight: 600;
            line-height: 1;
            display: block;
        
    }
}
@media only screen and (max-width: 767px){
    .getoutofdebtloanheader p.call-now{
        display:none;
    }
    .getoutofdebtloanheader .applyLink-col .applyWidgetRight{
        display:none;
    }
    .get-out-of-debt-container .navigation .header-phone{
        font-size: 22px;
    margin-top: 20px;
    }
}