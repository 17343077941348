.get-started-form {
  background: #000;
  display: flex;
  flex-direction: column;
  padding: 0 40px 20px;
  width: 355px;
  float: right;
  margin-top: 0px;
  position: relative;
  z-index: 1;

  &__top {
    top: 0;
    margin-right: 0;
    position: relative;
  }

  &__scrollable {
    position: fixed;
    top: 62px;
    margin-right: 30px;
    bottom: auto;
    width: calc(27.4% - 15px);
    //z-index: 999;
  }

  &__bottom {
    position: absolute;
    margin-right: 0;
    bottom: 0;
    top: auto;
  }

  .select-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &::-ms-expand {
        display: none;
        opacity: 0;
    }
    }
    &:after {
     content: '\f0d7';
     font: normal normal normal 18px/1 FontAwesome;
    font-size: 18px;
      color: #000000;
      right: 15px;
      top: 11px;
      padding: 0;
      position: absolute;
      pointer-events: none;
      font-size: 24px;
   
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    line-height: 1.4;
    color: #fff;
    margin-bottom: 18px;
    padding-top: 20px;
    padding-bottom: 0;
  }
  p {
    span {
      color: #fff;
      font-size: 12px;
      line-height: 1;
      display: block;
      margin-top: 10px;
      text-align: justify;
      font-family: 'Helvetica Neue';
    }
  }
}

@media screen and (min-width: 1321px) {
  .get-started-form {

    &__scrollable {
      width: 100%;
      max-width: 355px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .get-started-form {
    width: 100%;
    max-width: 355px;

    &__scrollable {
      width: calc(27.4% - 15px);
    }
  }
}

@media screen and (max-width: 1024px) {
  .get-started-form {
    width: auto;
  //   padding: 20px 20px 25px;
  //   h3 {
  //     padding-top: 0;
  //   }
  //   .bbb-block {
  //     margin: 0 -20px -25px -20px;
  //   }
  //   .phone-widget {
  //     margin: 0 -20px;
  //   }
  }
}

.mobile-form {
  display: none;
}

.mobile-get-started-form {
  display: block;
  max-width: 100% !important;
}


.form-control.get-started-form-input {
  background: #fff;
  border: 1px solid #ddd;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  padding: 13px 15px;
  border-radius: 0;
  width: 100%;
  //font-style: italic;
  line-height: normal;
  margin-bottom: 10px;
}

.form-control.get-started-form-input:focus {
  background-color: #ffffc1;
}

.form-control.get-started-form-input-invalid {
  background-color: #ffdfdf !important;
  //background-color: #ffdfdf !important; // red
 // background-color: #ffffc1 !important;
}

.get-started-form-input {
  background: #fff;
  border: 1px solid #ddd;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  padding: 13px 15px;
  border-radius: 0;
  width: 100%;
  //font-style: italic;
  line-height: normal;
  margin-bottom: 10px;
}

.get-started-form-input:focus {
  background-color: #ffffc1;
}

.get-started-form-input-invalid {
  background-color: #ffdfdf !important;
  //background-color: #ffdfdf !important; // red
 // background-color: #ffffc1 !important;
}
.free-consolidation-quote-form .form-group{
  margin-bottom:10px;
}


.get-started-form-submit {
  background-color: #ff0000;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
 
  font-weight: 700;
  padding: 15px 45px;
  text-decoration: none;
  white-space: normal;
  width: 100%;
  text-transform: uppercase;
  line-height: 1;
  display: inline-block;
  height: auto;
}

.btn.get-started-form-submit:hover {
  background-color: #ff0000;
  color: #fff;
}
.btn.get-started-form-submit {
  background-color: #ff0000;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
 
  font-weight: 700;
  padding: 15px 45px;
  text-decoration: none;
  white-space: normal;
  width: 100%;
  text-transform: uppercase;
  line-height: 1;
  display: inline-block;
  height: auto;
  border-radius:unset;
}

.get-started-form-submit:hover {
  background-color: #ff0000;
  color: #fff;
}
.get-started-form .bbb-block {
  padding: 10px 0;
  background: white;
  margin: 0 -40px -20px -40px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.lock {
  text-align: center;
  color: #505378;
  font-size: 12px;
  
  margin: 0 0 8px;
  font-weight: 600;
  padding-bottom: 0;
}

#moving-get-started-form-2 {
  h3 {
    padding-top: 0;
  }

  .sidebar-video {
    margin-left: -15%;
    margin-right: -15%;
  }
}

.hidden-field-wrapper {
  max-height: 0;
  transition: max-height 0.9s;
  overflow: hidden;
}

.unhidde-field {
  max-height: 200px;
  transition: max-height 2s;
}