
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Shadows+Into+Light+Two&display=swap&family=Lato:wght@300;900&display=swap');
.full-width-content .site-inner {
    background: #fff;
}

.template{
   .row{
        margin-left:0;
        margin-right:0;
    }
    h4{
        font-size: 15px;
    }
}
.template h2{
    font-size:20px; 

}
.template {
    h1 {
        font-size: 36px;
    
        line-height: 40px;
        font-weight: 300;
        color: #333;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
}

.container-fluid.template{
    padding-left:0;
    padding-right:0;

}
.template .site-inner{
    max-width:unset;
}
.tablepress td, .tablepress th {
    padding: 8px;
    border: none;
    background: 0 0;
    text-align: left;
    float: none!important;
    line-height:30px;
}
.content-sidebar-wrap {
    overflow: hidden;
    z-index: 9998;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
}

.content-sidebar-wrap {
    padding: 45px 0 30px;
}
.content-sidebar .content {
    float: left;
}

.full-width-content .content {
    width: 100%;
}
.content {
    float: right;
    width: 62%;
    padding: 0;
    position: relative;
    z-index: 9999;
}
.template .entry-content{
   
    p {
        line-height: 24px;
        margin: 0 0 24px;
        padding: 0;
        font-family: Arial, sans-serif;
    }
    .aligncenter{
        clear:both;
        display:block;
        margin:0 auto 24px;
    }
    a{
        
            color: #0000ff;
            text-decoration: underline;
        
    }
  
    
}

table {
    border-collapse: collapse;
    border-spacing: 0
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    line-height: 2;
    margin-bottom: 40px;
    width: 100%;
}

tbody {}

th,
td {
    text-align: left;
}

th {
    font-weight: bold;
    text-transform: uppercase;
}

td {
    padding: 6px 0;
}
table.tablepress tfoot th,
table.tablepress thead th {
    background: none;
}
table.tablepress {
    border: 1px solid #ddd;
}

td.column-1 {
    color: #c00;
}

td.column-2 {
    color: #287900;
}

td.column-3 {
    color: #c00;
}
td.column-3, td.column-5, td.column-6 {
    color: #3c0;
}
td.column-5 {
    color: #c00;
}

.alt, .even {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
}

.tablepress .row-hover tr:hover td {
    background-color: #f3f3f3;
}

@media only screen and (max-width: 780px) {
    .ml-responsive-table {
      display: block;
    }
.template .content{
    padding-left:15px;
    padding-right:15px;
}
  }