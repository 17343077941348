.list-item-content {
  display: flex;
  font-family: 'Product Sans';
  font-size: 16px;
  font-weight: 300;
  line-height: 1.44;
  letter-spacing: 0.8px;
  text-align: left;
  color: #707070;
  border-bottom: 1px solid #eaeaea;
  padding: 25px;
}

.logo {
  flex: 1;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.comments-container {
  display: flex;
  flex: 2;
  flex-direction: column;
  .ratings {
    padding: 10px 0;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    text-transform: capitalize;
  
    color: #535353;
 }
  .comments {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 667px) {
  .list-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      margin-bottom: 20px;
    }
    .ratings {
      display: flex;
      flex-direction: column;
      align-items: center; 
    }
    .comments {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
