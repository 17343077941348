@font-face {
  font-family: 'Product Sans';
  src: url('./fonts/ProductSans-Regular.woff2')
}

@font-face {
  font-family: 'BrandonGrotesque';
  src: url('./fonts/brandon-grotesque-bold-587bd6400afd0.woff')
}
@font-face{
  font-family: 'FontAwesome';
  src: url('./fonts/fontawesome-webfont.woff2');
}
@font-face{
  font-family: 'FontAwesome';
  src: url('./fonts/fontawesome-webfont.woff');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-color: #fff;
  color: #000;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px !important;

  line-height: 1.6;
}
html { font-size: calc(1em + 1vw); }




.about > .who, .culture, .locations, .stories, .reviews, .accolades, .benefits > div, .footer > div, .hero > div, .header> div, .video-carousel > div, .review-body > div{
  max-width: 1416px;
  margin: 0 auto;
}
.App .row{
margin-left:0 !important;
margin-right:0 !important;
}
.themeLayout.container-fluid{
  padding-left:0;
  padding-right:0;
}
.themeLayout .col-lg-8, .col-md-8{
  max-width:74.6%;
  flex: 0 0 74.6%;
}
.themeLayout .col-sm-4{
 max-width:25.4%;
  flex: 0 0 25.4%;
}
.themeLayout .no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.App {
  position:relative;
  
 
  .video-carousel {
    padding-left: 30px;
    padding-right: 30px;
    background-image: linear-gradient(to top, rgba(8, 4, 62, 0.62), rgba(0, 174, 239, 0.35)), linear-gradient(to bottom, #11073f, #11073f);
  }
  .header{
    background: #ffffff; 
  }
  .hero{
    background: #000000;
    flex-wrap: nowrap !important;
  }
  background-color: #eaebec;

  .review-body {
    padding-top: 60px;
  }
  .main-image-container {
    background-color: #000000;
    display: flex;
    justify-content: center;
   // width: 100%;
    img {
      object-fit: cover;
      height:638px;
      margin: 0 auto;
     width:100%;
    }
  }


  .text-box {
    position: absolute;
  
    top: 45%;

    margin: 0 auto;
    padding-left:30px;
    padding-right:30px;
    right:0; 
    width:100%; 
    h2 {
      width: 100%;
      padding-bottom: 0;
      font-size:36px;
      line-height:48px;
    }
    .text {
      width: 100%;
      color: #f2f2f2;
      font-size: 22px;
      line-height: 32px;
      
    }
  }
 
  .video-carousel-container {
    padding: 15px;
    background-image: linear-gradient(to top, rgba(8, 4, 62, 0.62), rgba(0, 174, 239, 0.35)), linear-gradient(to bottom, #11073f, #11073f);
  }

  #desktop-slider {
    display: none;
  }

  .slider-container {
    width: 100%;
   // margin-left:7%;
   // margin: 0 auto;
    //margin-right:0;

    .thumbnail {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      .video-container {
        position: relative;
        padding-top: 68.25%; 
        cursor: pointer;

        .video-player {
          position: absolute;
          pointer-events: none;
          padding: 10px;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .video-modal {
    background-color: white;
    border-radius: 10px;
    width: 80%;
    left: 10%;
    top: 10%;
    margin: auto 0;
    z-index: 999;
    padding: 20px;
    position: fixed;
  }

  .video-modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .slider-container.mobile {
    display: none;
    .mobile-video-text {
      color: white;
      padding: 5px;
      font-family: 'Product Sans';
      font-weight: bold;
      text-align: center;
    }
  }
  


  // .video-text {
  //   position: absolute;
  // }

  // .slick-slide:hover {
  //   width: 130px;
  //   transition: .5s linear;
  //   position: relative;

  //   div .thumbnail-wrapper .thumbnail  {
  //     position: relative;
  //     background-color: white;
  //     .video-text:hover {
  //       // position: absolute;
  //       background-color: white;
  //       left: 0;
  //       display: flex;
  //       justify-content: center;
  //       padding: 0 10px 10px 10px;
  //       font-family: 'Product Sans';
  //       font-size: 18px;
  //       line-height: 1.11;
  //       letter-spacing: 0.9px;
  //       text-align: center;
  //       width: 100%;
  //       color: #707070;
  //       z-index: 99999999999;
  //     }
  //   }
  // }

  .heading {
    font-family: BrandonGrotesque;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2.55px;
    line-height: 1;
    text-align: left;
    padding: 30px 0;
    font-size: 51px;
    color: #212529;
    
    &.small {
      font-size: 35px;
    }
    
    &.white { color: #ffffff; text-shadow: 0px 4px 24px rgba(0, 0, 0, 0.59); }
    
  }

  .text {
    font-family: Product Sans;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.76;
    letter-spacing: 1.28px;
    color: #4e5565;
  }

  h1 {
    font-family: BrandonGrotesque;
    font-weight: 500;
    line-height: 0.68;
    letter-spacing: 3px;
    text-align: left;
    color: #040c36;
    text-transform: uppercase;
   }
    .slick-slider {
      .slick-disabled {
        &::before {
          color: #d4d4d4  !important;
          cursor: not-allowed;
        }
      }
      .slick-prev {
        &::before {
          content: "\f104";
        }
      }
      .slick-next {
        &::before {
          font-family: "FontAwesome";
          content: "\f105";
        }
      }
    }
  }
  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
  }

  .hvr-grow:hover,
  .hvr-grow:focus,
  .hvr-grow:active {
      transform: scale(1.05);
      cursor: pointer;
  }






  @media screen and (min-width:767px) and (max-width: 960px) {    .App {
        
    .slider-container.desktop {
        display: block;
      }
      .text-box {
        position: absolute;
     //   width: 68%;
    //    top: 30%;
     //  right:0;
        margin: 0 auto;
        h2 {
          font-size:36px;
     //     width: 85%;
       //   font-size: 38px;
          padding-bottom: 0;
        }
        .text {
        //  width: 75%;
          color: #f2f2f2;
          font-size: 18px;
        }
      }
    } 
  
   
  } 


 /* @media only screen and (min-width: 1479px) {
    .App {
      .hero{
        flex-wrap: nowrap !important;
      }
    }
  }
*/
@media screen and (max-width: 1416px) { 
  .App {
   
  
  }
  .App .main-image-container img{
    width:unset;
  }
}

  @media screen and (max-width: 1196px) { 
    .App {
      .text-box {
        position: absolute;
      //  width: 83%;
        // top: 55%;
       // right:0; 
        margin: 0 auto;
      
      }
    
    }
    .themeLayout .col-lg-8, .col-md-8{
      max-width:69.6%;
      flex: 0 0 69.6%;
    }
    .themeLayout .col-sm-4{
     max-width:30.4%;
      flex: 0 0 30.4%;
    }
    
  }
 /* @media screen and (max-width: 739px) {
    .App .main-image-container img{
    object-fit: contain;
    width: 100%;
    margin: 0 auto;
    }
  }*/

  @media screen  and (min-width:768px) and (max-width: 1100px) {
    .themeLayout .col-lg-8, .col-md-8{
      max-width:66.6%;
      flex: 0 0 66.6%;
    }
    .themeLayout .col-sm-4{
     max-width:33.4%;
      flex: 0 0 33.4%;
    }
  }


@media screen and (max-width: 767px) {
  .themeLayout .col-lg-8, .col-md-8{
    max-width:initial;
    flex: initial;
  }
  .themeLayout .col-sm-4{
   max-width: 40%;
    flex: 0 0 40%;
  }
  .App .video-carousel{
    padding-left:15px ;
    padding-right:15px ;
  }

  .App .review-body .col-sm-4{
display:none;
  }
  .video-carousel-container .hvr-grow {
  max-width:300px;
  //margin:auto; 
  }
  .App .slider-container{
    width: 100%;
   // max-width: 300px;
   // margin: auto;
  }
  .video-carousel-container .slick-list{
    max-width:300px;
    margin:auto;
  }
 .hero > div > div{
padding-left:0; 
padding-right:0;
  }
  .video-carousel >div >div{
    padding-left:15px ; 
    padding-right:15px;
  }

  .App {
    .main-image-container {
      background-color: #000000;
      display: flex;
      justify-content: center;
      width: 100%;
      overflow:hidden;
      img {
        object-fit: cover;
        width: 100%;

        height: 380px !important;
        margin: 0 auto;
       // padding-left:30px;
        //padding-right:30px;

      }
    }
    .slider-container.desktop {
      display: none;
    }
    .slider-container.mobile {
      display: block;
    }
  
    .text-box {
      position: absolute;
      width: 100%;
      top: 40%;
      
      margin: 0 auto;
      h2 {
        width: 100%;
        font-size: 20px;
        padding-bottom: 0;
        padding-left:30px;
        padding-right:30px;
      }
      .text {
        width: 100%;
        color: #f2f2f2;
        line-height: 18px;
        font-size: 14px;
        padding-left:30px;
        padding-right:30px;
      }
    }
  }
  .App .review-body{
    padding-top:30px;
  }
  .App .text-box{
    padding-left:30px;
    padding-right:30px;
    right:unset; 
    width:100%; 
  }
  .App .text-box h2{
    line-height:24px;
    width:100%; 
  }
}

/*@media screen and (max-width: 640px) {
  .App .main-image-container img{
    object-fit: cover;
    width: 100%;
   // height:320x;
    margin: 0 auto;
    
    }
   
 
}*/




