.truste a {
  background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2015/07/truste-logo1.png)
    no-repeat;
  height: 100%;
  width: 150px;
  display: block;
}
.mcafee a {
  background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2015/07/mcafee-secure-logo1.png)
    no-repeat #fff;
  height: 70px;
  width: 130px;
  display: block;
}
.get-out-of-debt-container .footer ul li {
  width: 150px;
  height: 100%;
  border-left: none;
}
.get-out-of-debt-container .footer ul.mobile-copyright {
  display: none;
}
.get-out-of-debt-container {
  .footer {
    padding-bottom: 0;
    .privacy .text > div {
      width: fit-content;
      display: flex;
      margin: auto;
      padding-top: 30px;
      border-top: 0;
    }
    background: #333;
    .content {
      width: 1095px;
    }
  }
  .footer .disclaimer {
    margin-top: 0;
    .text {
      margin: auto;
      width: 1095px;
    }
  }
  .footer > div {
    width: 100%;
    max-width: unset;
  }
  .secondary-footer {
    background: #494949;
  }
}
.get-out-of-debt-container .footer .privacy > .text {
  border-top: 0;
}
@media only screen and (max-width: 767px) {
  .get-out-of-debt-container .footer .disclaimer .text {
    width: 100%;
  }
  .get-out-of-debt-container .footer .content .info {
    border-bottom: none;
  }
  .footer .content .info .address {
    text-align: center;
    margin: 0 auto;
  }
  .footer .content .social ul {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
  }
  .truste a {
    background: url(https://www.nationaldebtrelief.com/wp-content/uploads/2015/07/truste-logo1.png)
      no-repeat;
    height: 46px;
    width: 150px;
    margin-bottom: 30px;
    padding-left: 0;
  }
  .get-out-of-debt-container .footer {
    padding: 0 !important;
  }
  .get-out-of-debt-container .footer .privacy .text div {
    padding-top: 0;
  }
  .get-out-of-debt-container .footer .privacy .text div {
    display: none;
  }
  .get-out-of-debt-container .footer ul li:nth-child(3) {
    padding-top: 15px;
    width: 100%;
  }
  .get-out-of-debt-container .footer ul.mobile-copyright {
    display: block;
  }
}
