
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Shadows+Into+Light+Two&display=swap&family=Lato:wght@900&display=swap&family=OpenSans:wght@600;700&display=swap');
.apply-new-2{
    .row{
        margin:0;
        
    }
}
 .phone-widget.row{
     display:none;
 }
 .applyLinkmobile{
     display:none;
 }
 .apply-new-footer .trust-pilot-block{
     display:none;
 }
.page-one .apply_form_footer {
    width: 1200px;
    border: none;
    margin: 0 auto;
    padding: 0px;
    clear: both;
    text-align: center;
    button {
        position: relative;
        width: 303px;
        height: 79px;
        display: inline-block;
        text-transform: uppercase;
        font-size: 36px;
        line-height:42px;
        border-top:none;
        border-left:none;
        border-right:none;
        // font-size: 3.6rem;
        font-weight: 900;
        font-family: "Lato", sans-serif;
        background: #d40000;
        border-bottom: 3px solid #550000;
        border-right: 2px solid #550000;
        outline: none;
        -webkit-font-smoothing: antialiased;
        box-shadow: none;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;

        padding: 15px 20px;

        .fa-chevron-right {
            font-size: 26px;
            position: relative;
            top: -2.2px;
            margin-left: 15px;
            margin-right: 0;

            font-weight: 400;
            display: inline-block;
            /* font: normal normal normal 14px/1 FontAwesome; */
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            img {
                height: 26px;
                width: auto;
            }
        }
    }
}
.apply-new-2.container-fluid{
padding:0;
}
.apply-new-2 .site-inner{
    margin:0 auto; 
    max-width:unset;
}
.apply-new-2 .site-inner .content-sidebar-wrap {
    max-width: 100%;
    background: none;
    border: none;
    padding: 0;
    overflow: hidden;
    z-index: 9998;
  
    margin: 0 auto;
    position: relative;
}


.apply_content_wrapper {
width:100%; 
}


.apply-new-2 .page-one{
    background: url("../assets/img/form-bg-page-1.jpeg" ) #e1ebf1 0 0 no-repeat;

height:591px;
position:relative;
&.show{
    display:block; 
    } 
    &.hide{
    display:none; 
    }
}
.apply-new-2 .page-two{
    background: url("../assets/img/form-bg-page-1.jpeg" ) #e1ebf1 0 0 no-repeat;

height:591px;
position:relative;
&.show{
    display:block; 
    } 
    &.hide{
    display:none; 
    }
}
.apply_form_step_1_wrapper {
   max-width:1416px;
    position:relative;
    margin:0 auto; 

&.show{
display:block; 
} 
&.hide{
display:none; 
}

    .apply_form_title_wrapper {
        padding-top: 40px;
        margin: 0 auto;
        overflow: visible;
        width: 1200px;
        position: relative;
        .form_title_primary {
            text-align: center;
            color: #18027a;
            text-transform: none;
            font-family: "Roboto", serif;
            font-size: 36px;
           
            line-height: 36px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        .form_title_secondary {
            background: url(../assets/img/blue-sep.png) center bottom no-repeat !important;
            padding: 0 0 10px;
            margin: 0px 0 20px;
            font-family: 'Roboto', sans-serif;
            color: #383838;
            font-weight: 400;
            font-size: 18px;
           
            text-transform: uppercase;
            text-align: center;
        }
    }

    .form_description_wrapper {
        width: 380px;
        margin: auto;
        padding-left: 30px;
        position: relative;

        ul {
            padding: 0;

        }
        li {
            padding-left: 35px;
            margin-bottom: 13px !important;
            font-size: 18px;
          
            color: #272727;
            line-height: 18px;
            font-weight: 400;
            background: url(https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/applynew2/blue-checkmark.png) no-repeat !important;
            list-style: none !important;
            font-family: 'Roboto', sans-serif;
            //font-family: "Roboto", sans-serif;
        }
    }

    .apply_form_fields_wrapper {
        padding-top: 40px;
        margin: 0 auto;
        overflow: visible;
        width: 1200px;
        position: relative;
        margin-bottom: 15px;
    

        .apply_form_label {
            //  padding-top: 5px;
            margin: 0 0 10px;
            padding-left: 0;
            display: block;
            font-family: 'Shadows Into Light Two', cursive !important;
            text-align: center;
            font-weight: normal;
            font-size: 23px;
            //  font-size: 2.3rem;
            color: #000;
            p {
                margin: 0 0 10px;
                padding-left: 0;
                display: block;
                font-family: 'Shadows Into Light Two', cursive !important;
                text-align: center;
                font-weight: normal;
                font-size: 23px;
                padding: 0;

            }
        }

        .select_wrapper {
            width: 43% !important;
            overflow: hidden;
            box-shadow: 0 0 1px #aaa;
            border: 3px solid #eee;
            border-radius: 8px;

            z-index: 9995;
            position: relative;
            margin: 0 auto;
            background:white;
            select {
                margin: 0 auto;
                position: relative;
                width: 110%;
                border:none;
                outline:none;
                font-size: 27px !important;
                line-height: 42px;
                font-weight: 400;
                padding: 4px 12px;
                background:url(https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/applynew1/new-apply-select-dropdown.png) 88% 8px #f5fcfe no-repeat;
                
                border-radius: 5px;
                font-family: 'Roboto', sans-serif;
                text-indent: 0.01px;
                text-overflow: "";
                height: 54px;
                color: #000;

                &.invalid{
                    background-color:#ffdfdf !important;
                }
            }
        }
    }
  
}

.apply_form_step_2_wrapper {

 
    .apply_form_title_wrapper {
        padding-top: 20px;
        margin: 0 auto;
        overflow: visible;
        width: 580px;
        position: relative;
        padding-bottom: 5px;
        .form_title_primary {
            text-align: center;
            color: #18027a;
            text-transform: none;
            font-family: "Roboto", serif;
            font-size: 30px;
           
            line-height: 40px;
            font-weight: 700;
            margin-bottom: 0px;
        }

        .form_title_secondary {
            background: url(../assets/img/blue-sep.png) center bottom no-repeat !important;
            line-height: 40px;
            margin-bottom: 30px;
            width: 580px;
            margin: 0 auto 10px;
            font-family: "Roboto", sans-serif;
            font-weight: 400 !important;
            text-transform: none;
            text-align: center;
            font-size: 30px;
           
            color: #18027a;
            padding-bottom: 15px;
            position: relative;
         
           	font-size: 40px;
    
            font-weight: 700 !important;
        }
    }

    .form_description_wrapper {
        .description {
            padding: 0px;
            text-align: center;
            font-style: italic;
            margin: 0 auto 15px;
            font-size: 18px;

            color: #333;
            font-weight: 400;
            font-family: "Roboto", sans-serif;
            margin: 0 auto;
            position: relative;
            width: 100%;
            padding-left: 27px;
            line-height: 24px;
        }

        .progress-bar-graphic {
            background: url("../assets/img/progress-bar-graphic.png") no-repeat;
            width: 495px;
            height: 28px;

            padding-left: 30px;
            position: relative;
            margin: auto;
            margin-bottom:10px;
        }
    }

    .apply_form_fields_wrapper {
        width: 100%;
        label{
            font-size:0px; 
            }
        div {
            display: block;
            font-size: 1px;
            margin-bottom: 5px;
        }
        input {
            font-family: "Roboto", sans-serif;
            font-size: 16px !important;
            font-weight: 400;
            padding: 11px 15px 14px !important;
            border: none;
            color:#000000;
            -moz-box-shadow: 2px 2px 5px #ccc;
            -webkit-box-shadow: 2px 2px 5px #ccc;
            box-shadow: 2px 2px 5px #ccc;
            /* border: 1px solid #ccc; */
            width: 311px;
            border-radius: 5px;
            margin: 0 auto;
            float: none;
            display: block;
            margin-left: -134px;
            left: 50%;
            position: relative;
            margin-bottom: 13px !important;
                &.invalid{
                    background-color: #ffdfdf !important;
                }
                &::placeholder{
                    color:#000;
                }
        
        }
    }
   .apply_form_footer{ margin: 0 auto;     width: 1200px;
    border: none;
    margin: 0 auto;
    padding: 0px;
    clear: both;
    text-align: center;
span{
    color:white;
}}

      button {
    	position: relative;
	-webkit-appearance: button;
	width: 502px;
	height: 82px;
	display: inline-block;
	border-radius: 8px;
	border: 1px solid #cc0000;
	border-bottom: 6px solid #940000;
	box-shadow: 0 4px 5px rgba(0, 0, 0, .2);
	background: #df0000;

            .top-text{
                display: block;
                font-size: 24px;
              text-transform:uppercase;
              font-weight:900;
                line-height: 1;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
                font-family: "Lato", sans-serif;
            
    }
    .bottom-text {
        font-size: 18px;
       
        text-transform: none;
        font-weight: 400;
        font-size: 18px;
      font-family: "Lato", sans-serif;
        text-transform: none;
        font-weight: 900;
        display: block;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
    }
    .fa-chevron-right{
        
       
            position: absolute;
	right: 17px;
	top:17px;
	width:42.58px;
	height:44px;
	box-shadow: inset 0 3px 6px rgba(0, 0, 0, .4);
	border-radius: 30px;
	padding: 9px 11px 9px 13px;
	text-align: center;
      
    }
}

}

@media only screen and (max-width: 1023px){
    .apply-new-2 .page-two{
        background: url("../assets/img/form-bg-mobile.jpeg" ) #e1ebf1 0 0 no-repeat;

    }
   
#apply_page .apply_form_step_1_wrapper .trust-pilot-block{
  display:none;

}
.apply_form_step_2_wrapper .apply_form_footer{
    width:100%;
}
.apply_form_step_1_wrapper{
    max-width:760px;
    left:95px;
   
    .apply_form_title_wrapper{
        width:100%;
    }
}
.apply_form_step_2_wrapper .apply_form_fields_wrapper input{
    left:unset;
    margin-left:auto; 
}
.apply-new-2 .apply_form_step_1_wrapper .apply_form_fields_wrapper .select_wrapper{
    width:50% !important;
}
.apply_form_step_1_wrapper .apply_form_title_wrapper .form_title_primary{    font-size:32px;

}
.apply-new-2  .page-one{
    background-position: -180px top;
}
.apply_form_step_1_wrapper .apply_form_fields_wrapper{
    width:100%;
}
.page-one .apply_form_footer{
    width:100%;
}
.apply-new-2 .apply-new-footer{
    width:100%;
    padding-left:15px;
    padding-right:15px;
}
}


@media only screen and (max-width: 768px) {
    .phone-widget.row{
        display:block;
    }
    #apply_page .apply_form_step_1_wrapper{
        width:100%;
        padding-left:15px;
        padding-right:15px;
        max-width:unset;
        left:unset;

    }
    #apply_page .apply_form_step_1_wrapper .apply_form_fields_wrapper .apply_form_label {
		//padding-left:55px;
		width:100%;
		}
	#apply_page .apply_form_step_1_wrapper .apply_form_fields_wrapper .apply_form_label p{
		width:100%;
		}
        .apply_form_step_1_wrapper .apply_form_title_wrapper{
            width:100%;
        }

	#apply_page .apply_form_step_1_wrapper .apply_form_fields_wrapper .apply_form_label{
	
		margin-bottom: 20px;
		padding: 10px;
		//font-family: "Brandon Grotesque" !important;
		font-weight: 700;
		font-size: 20px;

	//	background: $blue;
		color: #fff;
		overflow: hidden;
	}
	#apply_page .apply_form_step_1_wrapper .apply_form_fields_wrapper .apply_form_label p{
		//font-family: "Brandon Grotesque" !important;
		font-weight: 700;
		font-size: 20px;

	//	background: $blue;
		color: #fff;
	}
	#apply_page .apply_form_step_1_wrapper .apply_form_fields_wrapper{
		//padding-left:15px; 
		//padding-right:15px; 
		}
	#apply_page .apply_form_step_1_wrapper .apply_form_fields_wrapper .select_wrapper{
		width:95% !important; 

		}

	#apply_page .apply_header .apply_header_wrapper .brand_logo .brand_title a{
		width: 130px;
		min-height: 39px;
	}

	#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact .icon_wrap{
	//	background: #18027A;
		color: #fff;
		border-radius: 50px;
		width: 30px;
		height: 40px;
		display: block;
		float: left;
		position: absolute;
		top: 0px;
		left: 0;

	}
	#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact .icon_nowrap{
		display:none; 
	}

	#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact .fa_phone{
		width:auto; 
		height:17px; 
		top:7px; 
	}
	#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact .phone{
	border:1px solid #18027a;
	height:40px; 
	position:relative; 
	}
	#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact .phone a{
	font-size:18px; 
}
#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact .phone_eyebrow{
display:none;
}

#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact .phone .phone-text{
line-height:1.15; 
margin-left:30px; 
}
#apply_page .apply_header .apply_header_wrapper .header_widget .brand_contact{
width:100%; 
margin-bottom:22px;
}
#apply_page .apply_header .apply_header_wrapper .header_widget{
width:160px; 
}
#apply_page .apply_header .apply_header_wrapper{
padding-left:10px; 
padding-right:10px; 
}

#apply_page .apply_form_step_1_wrapper .apply_form_title_wrapper .form_title_primary{
font-size:20px; 
margin-bottom:5px; 
line-height:36px; 
}
#apply_page .apply_form_step_1_wrapper{
right:0; 
position:relative;
}
#apply_page .apply_content_wrapper{
    overflow: hidden;
   // background: url("../../images/credit_org_mobile_bg.png") center top no-repeat #f0f1f1;
	height: auto;
	padding-left:0; 
}
#apply_page .apply_form_step_1_wrapper .form_description_wrapper{
width:100%; 
}
#apply_page footer .footer_top, #apply_page footer .footer_bottom{
flex-direction:column; 
}
#apply_page footer .footer-accredidation{
justify-content: center; 
text-align:center; 
margin-bottom:15px; 
}
#apply_page footer .footer_breadcrumb_links{
text-align:center; 
margin-bottom:15px;
margin-left:auto;

}

#apply_page footer .copyright p{
padding-bottom: 0; 
}


#apply_page footer .copyright{
justify-content: center;
text-align:center; 
margin-bottom:0px; 
}
#apply_page .apply_form_step_2_wrapper .apply_form_title_wrapper .form_title_primary{
font-size:18px; 
}

.apply_form_step_1_wrapper .apply_form_title_wrapper .form_title_secondary{
font-size:22px; 
font-style: italic;
background:none !important;
text-transform:none;


}

#apply_page .apply_form_step_2_wrapper .form_description_wrapper .description{
margin-bottom:20px; 
}
#apply_page .apply_form_step_2_wrapper .form_description_wrapper{
margin-bottom:20px; 
}

#apply_page .apply_form_step_2_wrapper .form_description_wrapper .progress-bar-graphic{
	height: 11px;
    width: 279px;
    background: url("../assets/img/mobile-progress-bar.png") no-repeat;
}
.apply_form_step_2_wrapper button .bottom-text{
    line-height:18px;
    font-size:16px;
}
#apply_page .apply_form_step_1_wrapper .apply_form_footer{
width:100%; 
margin-bottom: 15px; 
cursor:pointer; 
}
#apply_page .apply_form_step_2_wrapper .apply_form_footer{
	width:100%; 
	button {
	
	width: 290px;
    height: 100px;
    padding: 10px 80px 10px 20px;
    text-align: left;

	}
}

#apply_page .apply_form_step_2_wrapper .apply_form_title_wrapper{
width:100%; 
padding-top:20px; 
}

#apply_page .apply_form_step_2_wrapper .apply_form_footer{
margin-bottom:20px; 
}

#apply_page .apply_form_step_2_wrapper{
position:relative; 
}


#apply_page .apply_form_step_2_wrapper .apply_form_footer .apply_form_policy{
width:100%; 
padding-left:20px; 
padding-right:20px; 


}
#apply_page .apply_form_step_1_wrapper .form_description_wrapper ul{
display:table; 
margin:auto; 
padding:0; 
}


#apply_page .apply-new-footer-wrap {
    display:flex;
    flex-direction:column;
    padding-left:15px;
    padding-right:15px;
}
#apply_page .apply-new-footer-wrap .cred{
    display:flex;
    flex-direction:column;
}
#apply_page .apply-new-footer-wrap .cred a{
margin-bottom:30px;
}
#apply_page .apply-new-footer{
    display:flex;
    flex-direction:column;
    width:100%;
}
.apply-new-footer .copyright{
    margin:auto; 
    text-align:center;
    float:none;
}
.apply-new-footer .trust-pilot-block{
    position:unset;
margin:auto;
margin-left:auto;
margin-right:auto;
float:none;
margin-bottom:30px;

width: 270px;
.ca-reviews {
    padding: 10px 10px 0px;
    height: 490px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #eee;
}
.consumer-affairs{
display:none;
}
}
.apply_form_step_1_wrapper .apply_form_fields_wrapper{
    width:100%;
}
#apply_page .apply_form_step_1_wrapper .apply_form_fields_wrapper .apply_form_label{
    margin-bottom: 20px;
    padding: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 700;
    font-size: 18px;
   
    background: #4c62d0;
    color: #fff;
    overflow: hidden;
    p{
        font-family: "Open Sans", sans-serif !important;
        font-weight: 700;
        font-size: 18px;
       
        background: #4c62d0;
        color: #fff;
        overflow: hidden;
    }
}
#apply_page .apply_form_step_1_wrapper{
    padding-left:0;
    padding-right:0;
}



.apply-new-2 .page-one{
    background: url("../assets/img/form-bg-mobile.jpeg" ) #e1ebf1 0 0 no-repeat;
}
.apply_form_step_1_wrapper .apply_form_fields_wrapper .apply_form_label p{
    margin:0;
}
.phone-widget.row{
    display:block;
}
.applyLinkmobile{
    display:block;
    margin:auto;
    margin-right:0;
    padding-right:15px;
    width:fit-content;
}

.apply_form_step_2_wrapper .apply_form_title_wrapper .form_title_secondary{
    font-size:18px;
    width: 100%;
    background: none !important;
}
.apply-new-2 .page-two input {
    
    width: 260px;
}
.apply_form_step_2_wrapper .form_description_wrapper .description{
    padding-left:0;
}
}

@media only screen and (max-width: 479px){
.apply-new-2 .site-title a, .apply-new-2 .site-title a:hover, .apply-new-2 .site-title a:focus {
    width: 130px;
    min-height: 39px;
}
}