

@font-face {
	src: url(../assets/fonts/shadows-into-light-two.regular.ttf), url(../assets/fonts/shadows-into-light-two.regular.woff), url(../assets/fonts/shadows-into-light-two.regular.woff2);
	font-family: "Shadows Into Light Two";
}

@font-face {
	src: url(../assets/fonts/roboto.regular.ttf), url(../assets/fonts/roboto.regular.woff), url(../assets/fonts/roboto.regular.woff2);
  font-family: "Roboto";
  font-weight: 400; 
}
@font-face {
	src: url(../assets/fonts/roboto.700.ttf), url(../assets/fonts/roboto.700.woff), url(../assets/fonts/roboto.700.woff2);
  font-family: "Roboto";
  font-weight: 700; 
}
.thankyou .row{
  margin-left:0;
  margin-right:0;
}

.thank-you-for-applying, .thankyou .sitemap-aside {
  font-size: 13px;
  margin-bottom: 20px;
  font-weight:400;
 
  font-family:Helvetica Neue,HelveticaNeue-Light,Helvetica Neue Light,Helvetica,Arial,Lucida Grande,sans-serif;
h1{
    font-size:32px;
    padding: 0 0 40px;
}
h2{
    color: #20037d;
    line-height: 1.2;
    padding: 0 0 40px;
    margin: 0;
    padding-top: 45px;
}
p{
    line-height:24px;
    font-weight: 400;
    font-family: Helvetica Neue,HelveticaNeue-Light,Helvetica Neue Light,Helvetica,Arial,Lucida Grande,sans-serif;

}
.thank-you-video{
    h1{
        font-weight: 400;
        color: #39a000;
        font-size: 32px;
        
    }
    .embed-container{
    display:flex; 
     iframe{
        max-width:560px;
         height:315px;
          width:100%; 
        margin:auto;
     }   
    }

}
.thank-you-bottom{
  padding-right:45px;
}
  .repayment-list {
    padding-left: 0;
    p{
        padding: 0 0 40px;
        margin: 0;
        font-size:16px;
    }
  }
  li {
    margin: 0;
    list-style: none;
    padding-left: 80px;
  }
  .one {
    background: url(../assets/img/step-one-thank-you.png) 3px 12px no-repeat;
  }
  .two {
    background: url(../assets/img/step-two-thank-you.png) 3px 12px no-repeat;
  }
  .three {
    background: url(../assets/img/step-three-thank-you.png) 3px 12px no-repeat;
  }
  .four {
    background: url(../assets/img/step-four-thank-you.png) 3px 12px no-repeat;
  }
  .trust-pilot-logo {
    a {
      display: block;
      background: url(../assets/img//trust-pilot-logo-144.png) center no-repeat;
      width: 100px;
      height: 24px;
      background-size:contain;
      margin:auto;
     // width: 100%;
     // height: 13px;
      padding: 6px 0;
      border: none;
    }
  }

}
.testimonials .trust-pilot-logo a {
    text-indent: -9999em;
    display: block;
    background: url(../assets/img/trust-pilot-logo-144.png) center no-repeat;
    width: 100px;
    height: 24px;
    margin: 0 auto;
    background-size: 100%;
}
.right-box-core-trustpilot {
    height: auto;
    overflow: auto;
    padding: 0; 
    .trustpilot-widget{
    padding: 0 20px; 
    }
    .tp-body {
        .sq-meta {
            overflow: hidden;
            clear: both;
          //  margin-right:2px;
        }
        .pq-attr {
            text-align: right;
            float: right;
            clear: both;
            font-size:16px;
            line-height: 20.8px;
        }
      .five-star{
      text-align:right;
      img{
          margin-right:2px;
      }
      }
      .smallquote {
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 20px;
      padding-bottom: 16px;
      .quote-title{
          font-weight:700;
          font-size: 16px;
      }
      .quote-content-wrap {
        float: right;
        width: 82%;
        margin-bottom: 5px;
        
        p {
          margin: 0 0 5px;
          line-height: 22.4px;
          color: #7e7e7e;
          padding: 0px;
          overflow: hidden;
          font-size: 16px;
        }
        
      }
      .fa-user {
        padding: 7px 10px;
        border: 1px solid;
        border-radius: 50%;
        width: 8%;
        color: #2B944A;
        float: left;
        font-size: 34px;
        width: auto;
        display: block;
      }
   
      }
    }
  }
  .right-box-core-trustpilot .tp-overview .screen-reader-text {
    position: absolute;
    clip: rect(0,0,0,0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}
.right-box-core-trustpilot .tp-overview .five-star {
    float: none;
    margin: 0 0 5px 2px;
    overflow:hidden;
    line-height:32px;
    img{
        margin-right:2px;
    }
}
.thank-you-content {
//  width: 68%;
  margin: 0 auto;
  float: left;
  width:100%;
}

@media screen and (max-width: 1340px) {
  .thank-you-content {
  //  width: 63%;
  }
}

@media screen and (max-width: 1199px) {
  .thank-you-content {
   // width: 60%;
  }
}

@media screen and (max-width: 1023px) {
  .thank-you-content {
    width: 100%;
    h2 {
      padding-bottom: 15px;
      font-size: 22px;
    }
    .repayment-list {
      padding-left: 0;
      .four {
        p {
          padding-bottom: 0;
        }
      }
    }
  }
  .thank-you-for-applying {
    margin-bottom: 0;
    .thank-you-sidebar {
    width: 100%;
  }
  }
}

.thank-you-sidebar {
 min-width:300px;
  float: right;
}

.testimonials, .why-choose-us {
  h4 {
    line-height: 32px;
    margin-bottom: 25px;
    font-weight: normal;
    font-size: 26px;
    color: #fff;
    background: #3f9ad2;
    text-align: center;
    padding: 7px 0 8px;
  }

}
.testimonials {
  background: url(../assets/img/sidebar-header-bg-arrow.png) center 47px no-repeat #f9f9f9;
  padding-bottom: 25px;
}

.why-choose-us {
  background: url(../assets/img/sidebar-header-bg-arrow.png) center 47px no-repeat #f9f9f9;
  clear: both;
  p {
      font-size:13px;
    color: #7e7e7e;
    padding: 0px 20px 20px;
    overflow: hidden;
    line-height:24px;
    margin-bottom:0;
  }
  a {
    display: block;
    text-indent: -9999em;
    float: left;
    margin: 0px 16px;
    border: none;
  }
}

.tp-overview {
    text-align: center;
    .excellent {
      font-size: 30px;
      margin: 0;
      line-height: 30px;
      color: #383838;
      padding: 0;
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
      margin: 0 0 5px;
    }
    p{
        font-size:16px;
        line-height:24px;
    }
}
.thank-you-top {
  padding-bottom: 50px;
  background: url(../assets/img/content-shadow.png) center bottom no-repeat;
  margin-bottom: 20px;
  padding-right:45px;
  p{
      font-size:16px;
  }
  p:nth-child(3) {
    padding-bottom: 15px;
  }
  & > h1 {
    margin: 15px 0;
    font-weight: normal;
    color: #39a000;
    font-size: 32px;
    padding-bottom: 0;
  }
}

.green-phone {
  line-height: 46px !important;
  font-size: 30px;
  color: #39a000;
  font-weight: 700 !important;
  width: 250px;
  margin: 25px auto 0;
  text-align: center;
  padding-left: 28px;
  padding-bottom: 0;
  background: url(../assets/img/small-phone.png) 16px center no-repeat #f9f9f9;
  & > a {
    color: #39a000;
    text-decoration: none;
    border: none;
    font-size: 30px;
  }
}

.free-book-wrap {
  display: flex;
  .free-book-one, .free-book-two {
    //margin: 10px 120px;
    width: 50%;
    padding-bottom: 20px;
  }
  .book-download-link{
      line-height:32px;
     
      text-decoration:underline; 
  }
}

.sidebar-logos {
  display: flex;
  justify-content: center;
  .bbb-logo {
    background: url(../assets/img/bbb-vertical-logo.png) no-repeat;
    width: 57px;
    height: 91px;
  }
  .top-ten-excellence-logo {
    background: url(../assets/img/ttr2019-small.png) no-repeat;
    width: 72px;
    height: 116px;
  }
}

.list-highlight {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
}
.site-inner {
    clear: both;
    padding-top: 0;
    word-wrap: break-word;
    max-width:1416px;
    margin:auto !important;
    .aside-col{
        justify-content:flex-end !important;
    }
  }
  
  // @media screen and (max-width: 1248px) and (min-width: 1132px) {
  //   .content-sidebar-wrap {
  //     main {
  //       width: 100% !important;
  //     }
  //     aside {
  //       width: 30% !important;
  //     }
  //   }
  // }
  
  // @media screen and (max-width: 1132px) and (min-width: 1030px) {
  //   .content-sidebar-wrap {
  //     main {
  //       width: 75% !important;
  //     }
  //     aside {
  //       width: 25% !important;
  //     }
  //   }
  // }
  
  // @media screen and (max-width: 1030px) {
  //   .content-sidebar-wrap {
  //     main {
  //       width: 100% !important;
  //     }
  //     aside {
  //       width: 35% !important;
  //     }
  //   }
  // }
  
  .site-inner__padding-top_0 {
    padding-top: 0 !important;
  }
  
  .content-sidebar-wrap {
    margin: 0 auto;
    max-width: 1360px;
    display: flex;
    justify-content: space-between;
    // aside {
    //   width: auto;
    // }
  }
  
  .content {
    float: left;
    width: 72.6%;
    // border-right: 1px solid #c3d0eb;
    // padding-left: 65px;
    padding: 40px 0;
  }
  
  .content-one {
    width: 100%;
    border-right: none;
    padding-top: 0;
  }
  
  .thankyou .entry-content {
    padding-right: 50px;

  }
  
  .content-half {
    padding: 35px 0 35px 50px;
    width: 68.5%;
    overflow: hidden;
    height: 615px;
    position: relative;
    float: left;
    background-color: #fff;
  }
  
  .entry-title {
    color: #20037d;
  
    margin-bottom: 15px;
    padding: 0;
    font-size: 36px;
    line-height: 1.2;
    text-decoration: none;
    font-weight: 600;
    margin: 0;
    padding-bottom: 40px;
  
    &__first-line {
      padding: 0;
      font-size: 24px;
      color: #000;
    }
  
  }
  
  .content-wrap {
    width: 62%;
    float: left;
    padding-right: 3%;
  }
  
  
  .deluxe-image-wrap {
    width: 38%;
    float: left;
    position: absolute;
    right: 0;
    bottom: -10px;
  }
  @media screen and (max-width: 1199px) {
    
    
  }
  @media screen and (max-width: 1240px) {
    .deluxe-image-wrap {
      display: none !important;
    }
  .content-wrap {
      width: 100% !important;
      float: none !important;
    }
   
    .cta {
      p {
        font-size: 20px;
      }
    }
  }
  
  @media screen and (max-width: 1023px) {
    .content-half {
      .entry-title {
        padding: 0;
      } 
    }
    .site-inner .d-flex{
        flex-direction:column !important;
    }
  .thank-you-for-applying{
     flex: 0 0 100% !important;
     max-width:100% !important;
  }
    .site-inner {
        flex-direction:column;
        .aside-col{
            width:100%;
            max-width:100%; 
            flex: 0 0 100% !important;
            .thank-you-sidebar{
                width:100%;
            }
        }
      .content-sidebar-wrap {
        padding-left: 0;
        padding-right: 0;
      }
    }
  
    article {
      padding: 0 2.5%;
      font-size: 16px;
      h2 {
        font-size: 26px;
      }
    }
    .video-cta {
      .one-half {
        width: 100%;
      }
    }
    .columns-content {
      p {
        font-size: 16px !important;
      }
      .one-half {
        width: 100%;
      }
    }
    .entry-title {
      font-size: 22px;
      padding: 0 2.5%;
    }
    .content-wrap {
      font-size: 16px;
    }
    .deluxe-banner {
      .content-half {
        padding: 25px 3.5%;
        width: 100%;
        height: auto;
      }
    }
    .content-half {
      width: 100% !important;
      height: 100% !important;
    }
    .form-half {
      width: 100% !important;
      &::after, &::before {
        content: none !important;
      }
    }
    .deluxe-banner {
      height: auto;
      overflow: hidden;
      width: 100%;
      //height: 950px;
    }
  
  }
  
  .form-half {
    position: relative;
    top: -12px;
    height: 639px;
    width: 31.5%;
    float: right;
    background: #000;
    padding-top: 30px;
  
    .get-started-form {
      float: none;
      margin: auto;
      padding: 0 40px 40px;
  
      h3 {
        padding: 0;
      }
  
      .get-started-form-input {
        padding: 10px 12px;
        margin-bottom: 15px;
      }
    }
  
    .bbb-block {
      margin: auto;
      border: 1px solid #c3d0eb;
  
      .lock {
        padding: 0;
      }
    }
  
    &:before {
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent #000 transparent;
      position: absolute;
      top: 0;
      left: -12px;
    }
  
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12px 12px 0;
      border-color: transparent #000 transparent transparent;
      position: absolute;
      bottom: 0;
      left: -12px;
    }
  }
  
  .video-cta {
    overflow: hidden;
    padding-bottom: 40px;
  }
  
  .phone-content {
    background: #f4f7fa;
    border: 1px solid #d1daeb;
    text-align: center;
    padding: 35px;
    .phone-text {
      font-size: 22px;
      color: #252365;
      font-weight: 400;
      line-height: 1.3;
      max-width: 85%;
      margin: 0 auto;
    }
    .phone {
      font-size: 24px;
      color: #252365;
      font-weight: 700;
      margin-bottom: 0;
      .fa {
        color: #e6171e;
      }
    }
  }
  
  main {
    header {
      border-bottom: none;
    }
  }
  
  .deluxe-section.has-bg {
    margin-right: -100vw;
    margin-left: -100vw;
    padding: 40px 100vw 0;
    background: #f2f5f8;
  }
  
  .columns-content {
    overflow: hidden;
    p {
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      padding-bottom: 40px;
    }
    .first {
      padding-left: 0;
    }
    h1 {
      font-size: 36px;
      font-weight: 700;
    }
  }
  
  .sidebar-video {
    padding: 20px;
  }
  
  .hidden {
    display: none;
  }
  
  #moving-get-started-form-2 {
    // width: 356.3px;
    .lock {
      padding-top: 5px;
      padding-bottom: 15px;
      color: #afaebe;
    }
    .phone-text {
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      padding: 0;
    }
    .bbb-block {
      padding: 20px 0;
    }
  }
  /*
  .thankyou .phone-widget {
    background: #f4f7fa;
    text-align: center;
    padding: 20px 25px 15px;
    border-bottom: 1px solid #d1daeb;
    margin: 0 -40px;
  
    a {
      color: #333;
      border: none;
      font-weight: 700;
      font-size: 24px;
     // font-size: 2.6rem;
      line-height: 1.3;
      .fa {
        color: #e6171e;
      }
    }
  }
  */
  #has-bg-2 {
    // background: #E1E7F0;
  }
  
  .bottom-content {
    p {
      margin: 0;
      padding: 0;
    }
    .video-cta {
      margin-bottom: 10px;
    }
  }
  
  .one {
    width: 100% !important;
    border-right: none !important;
  }
  
  .simple-ul {
    list-style-type: disc;
    margin: 0;
  
    p {
      margin-bottom: 0 !important;
    }
  }
  
  
  .deluxe-section {
    a:hover {
      text-decoration: underline;
    }
    .one-half {
      p {
      }
    }
  }
  
  .can-help-image {
    height: auto;
    max-width: 100%;
  }
  
  .bg__p__margin_bottom_25 {
    p {
      margin: 0 0 25px;
      padding: 0;
    }
  }
  
  .bg__margin_bottom_20 {
    margin-bottom: 20px;
    padding-bottom: 0;
  }
  
  .bg__margin_top_20 {
    margin-top: 20px;
  }
  
  .simple-ul__p__padding_0 {
    margin: 0;
    padding-bottom: 40px;
    p {
      padding: 0;
    }
  }
  
  .simple-ul__p__margin_0 {
    p {
      margin-bottom: 0 !important;
    }
  }
  
  .simple-ul__margin_top_0 {
    margin-top: 0;
  }
  
  .simple-ul__margin_bottom_30 {
    margin-top: 0;
    margin-bottom: 30px
  
  }
  
  .state-list {
    padding-left: 0;
    li {
      border-top: 1px solid #eee;
      padding: 6px;
      line-height: 2;
      a {
        color: #0000ff;
        text-decoration: none;
        border-bottom: 1px solid;
        &:hover, &:focus {
          text-decoration: none;
          color: #000000;
  
        }
      }
    }
  }
  
  .entry-header {
    
  }
  
  .deluxe-section__alternative_h {
    h2 {
      font-size: 26px;
      font-weight: 400;
    }
    h3 {
      font-size: 22px;
    }
  }
  
  .alignright {
    float: right;
    text-align: right;
    margin: 0 0 10px 10px;
  }
  
  .top-content {
    margin-bottom: 60px;
  }