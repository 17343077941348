
.financial-calculator {
  a {
    color: #0000ff;
    text-decoration: underline;
}
  .header.row{
    margin-left:0;
    margin-right:0;
  }
  .footer.row{
    margin-left:0;
    margin-right:0;
  }
  .main-footer.row{
    margin-left:0;
    margin-right:0;
  }
  .content-sidebar-wrap{
    display:block;
  }
  #KJEAllContent h1, #KJEAllContent h2, #KJEAllContent h3, h1.KJEFontTitle, table.KJEReportTable .KJEHeading, table.KJECalcTable .KJEHeading{
    font-size:14px;
  }
    .content-sidebar-wrap {
        p{
         img {
           clear: both;
           display: block;
           height: auto;
           margin: 0 auto 24px;
           max-width: 100%;
         }
       }
         h2 {
           font-size: 30px;
          
           line-height: 30px;
           font-weight: 500;
           color: #18027a;
           a {
             font-weight: 700;
           }
         }
       
         .entry-title {
           font-size: 36px;
           font-weight: 300;
        
           line-height: 40px;
           font-weight: 300 !important;
           color: #333;
           font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
           line-height: 1.25;
           margin: 0;
           margin-bottom: 10px;
           padding: 0;
         }
         .after-content-form {
           background: #006896;
           overflow: hidden;
           margin: 0;
           max-width: 100%;
           padding: 20px;
           box-shadow: none;
           .get-started-form-input {
             width: 60%;
           }
       
           .do-you-qualify-button {
             width: 38%;
             float: right;
             clear: none;
             padding: 0 1.5% 0 0;
           }
           button {
             margin-bottom: 0;
             padding: 11px 16px;
             border-radius: 3px;
             width: 100%;
             font-size: 18px;
             font-weight: 600;
             text-transform: uppercase;
             background: #1f00a9;
             text-align: left;
             position: relative;
             line-height: 24px;
             color:white;
             &:hover {
               background-color: #00ab66;
               .fa {
                 background-color: #079031;
               }
             }
       
             .fa {
               position: absolute;
               right: 0;
               font-size: 20px;
               
               top: 0px;
               background: #18027a;
               padding: 12px 12px;
               border-radius: 0 5px 5px 0;
               margin-right: 0;
               img{
                height:20px;
               width:auto;
               }
             }
           }
           input {
             background-color: #fff;
             color: #333;
             border-radius: 3px;
             font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
             font-size: 16px;
             padding: 12px;
             width: 100%;
             border: 1px solid #ccc;
           }
           h3 {
             font-size: 36px;
       font-weight:700;
             color: #fff;
             //  font-weight: 300 !important;
             line-height: 36px;
             text-align: center;
             margin: 0 auto 20px;
             max-width: 100%;
             font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
             padding: 0;
           }
           .select-wrapper {
             width: 95%;
             float: left;
             padding-left: 1.5%;
           }
         }
         .mobile-only-form {
           display: none;
           h3 {
             color: #18027a;
             text-align: center !important;
             text-transform: none !important;
           }
           form {
             width: 100%;
             margin-bottom: 15px;
             max-width: 100%;
             padding: 0;
             background: transparent;
             float: none;
             h3 {
               padding-left: 0;
               margin-bottom: 20px;
               font-size: 21px;
               text-align: left;
               text-transform: uppercase;
               font-weight: 400;
               line-height: 36px;
               padding: 0;
             }
             input,
             select {
               width: 100%;
               font-size: 26px;
               line-height: 32px;
               font-size: 16px;
               padding: 5px;
       
               border-radius: 0px;
               border: 1px solid #cbccce;
               background: #fbfdfc;
               margin-bottom: 15px;
             }
           }
           h4 {
             font-size: 32px;
             line-height: 32px;
             font-weight: 300;
             color: #e36d0d;
             text-shadow: 1px 1px 1px #000;
             text-align: center;
             margin-top: 0px;
             margin-bottom: 0px;
           }
         }
         .after-content-form {
           background: #006896;
           overflow: hidden;
           margin: 0;
           max-width: 100%;
           padding: 20px;
           box-shadow: none;
           .get-started-form-input {
             width: 60%;
           }
       
           .do-you-qualify-button {
             width: 38%;
             float: right;
             clear: none;
             padding: 0 1.5% 0 0;
           }
           button {
             margin-bottom: 0;
             padding: 11px 16px;
             border-radius: 3px;
             width: 100%;
             font-size: 18px;
          
             font-weight: 600;
             text-transform: uppercase;
             background: #1f00a9;
             text-align: left;
             position: relative;
             line-height: 24px;
             &:hover {
               background-color: #00ab66;
               .fa {
                 background-color: #079031;
               }
             }
       
             .fa {
               position: absolute;
               right: 0;
               font-size: 20px;
               top: 0px;
               background: #18027a;
               padding: 11px 12px;
               border-radius: 0 5px 5px 0;
               margin-right: 0;
             }
           }
           input {
             background-color: #fff;
             color: #333;
             border-radius: 3px;
             font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
             font-size: 16px;
            
             padding: 12px;
             width: 100%;
             border: 1px solid #ccc;
           }
           h3 {
             font-size: 36px;
       
             color: #fff;
             //  font-weight: 300 !important;
             line-height: 36px;
             text-align: center;
             margin: 0 auto 20px;
             max-width: 100%;
             font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
             padding: 0;
           }
           .select-wrapper {
             width: 95%;
             float: left;
             padding-left: 1.5%;
           }
         }
         .get-started-form-input {
           font-family: Arial;
           font-size: 16px;
         }
       }
  .entry-title {
    font-size: 36px;
    font-weight: 300;
 
    line-height: 40px;
    font-weight: 300 !important;
    color: #333;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.25;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
  }
  #KJEAllContent {
    padding: 10px 0 0 0;
  }
  .KJELogo {
    display: none;
  }
  .KJECommandButton{
      
        display: inline-block;
        background-color: #18027A!important;
        text-transform: uppercase;
        border: none;
        border-radius: 3px;
        box-shadow: none;
        color: #fff;
        cursor:pointer ;
        padding: 15px 25px;
        width: auto;
      
  }
}


@media only screen and (max-width: 767px) {
  .financial-calculator .content-sidebar-wrap .after-content-form   h3 {
 font-size: 24px;
}
.financial-calculator .content-sidebar-wrap .after-content-form  .select-wrapper {
 width: 100%;
}
.financial-calculator .content-sidebar-wrap .after-content-form .get-started-form-input {
 width: 52%;
}
.financial-calculator .content-sidebar-wrap .after-content-form  .do-you-qualify-button {
 width: 45%;
}
.financial-calculator .content-sidebar-wrap .after-content-form  button {
 margin-left: 0;
}
}


@media only screen and (max-width: 560px) {
  .financial-calculator .content-sidebar-wrap .after-content-form .get-started-form-input {
 width: 100%;
 
}
.financial-calculator .content-sidebar-wrap .after-content-form .do-you-qualify-button {
 width: 100%;
 margin-top:15px;
}

.financial-calculator .content-sidebar-wrap .after-content-form {
 padding: 10px;
}
}
@media (max-width: 760px) {
  table.tablepress {
    display: none;
  }
  .financial-calculator .content-sidebar-wrap{
    padding-left:15px;
    padding-right:15px;
  }
}
