.simple-content-page{
.content-sidebar-wrap {
 p{
  img {
    clear: both;
    display: block;
    height: auto;
    margin: 0 auto 24px;
    max-width: 100%;
  }
}
  h2 {
    font-size: 30px;
    font-size: 3rem;
    line-height: 30px;
    font-weight: 500;
    color: #18027a;
    padding: 0;
    margin-bottom: 10px;
    a {
      font-weight: 700;
    }
  }
  p {
    margin: 0 0 26px 0;
    padding: 0;
  }

  .entry-title {
    font-size: 36px;
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 40px;
    font-weight: 300 !important;
    color: #333;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.25;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
  }
  .after-content-form {
    background: #006896;
    overflow: hidden;
    margin: 0;
    max-width: 100%;
    padding: 20px;
    box-shadow: none;
    .get-started-form-input {
      width: 60%;
    }

    .do-you-qualify-button {
      width: 38%;
      float: right;
      clear: none;
      padding: 0 1.5% 0 0;
    }
    button {
      margin-bottom: 0;
      padding: 11px 16px;
      border-radius: 3px;
      width: 100%;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: 600;
      text-transform: uppercase;
      background: #1f00a9;
      text-align: left;
      position: relative;
      line-height: 24px;
      &:hover {
        background-color: #00ab66;
        
        .fa {
          background-color: #079031;
        }
      }

      .fa {
        position: absolute;
        right: 0;
        font-size: 20px;
        top: 0px;
        background: #18027a;
        padding: 13px 12px;
        border-radius: 0 5px 5px 0;
        margin-right: 0;
      }
    }
    input {
      background-color: #fff;
      color: #333;
      border-radius: 3px;
      font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-size: 1.6rem;
      padding: 12px;
      width: 100%;
      border: 1px solid #ccc;
    }
    h3 {
      font-size: 36px;

      color: #fff;
      //  font-weight: 300 !important;
      line-height: 36px;
      text-align: center;
      margin: 0 auto 20px;
      max-width: 100%;
      font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
      padding: 0;
    }
    .select-wrapper {
      width: 95%;
      float: left;
      padding-left: 1.5%;
    }
  }
  .mobile-only-form {
    display: none;
    h3 {
      color: #18027a;
      text-align: center !important;
      text-transform: none !important;
    }
    form {
      width: 100%;
      margin-bottom: 15px;
      max-width: 100%;
      padding: 0;
      background: transparent;
      float: none;
      h3 {
        padding-left: 0;
        margin-bottom: 20px;
        font-size: 2.1rem;
        text-align: left;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 36px;
        padding: 0;
      }
      input,
      select {
        width: 100%;
        font-size: 26px;
        line-height: 32px;
        font-size: 1.6rem;
        padding: 5px;

        border-radius: 0px;
        border: 1px solid #cbccce;
        background: #fbfdfc;
        margin-bottom: 15px;
      }
    }
    h4 {
      font-size: 3.2rem;
      line-height: 32px;
      font-weight: 300;
      color: #e36d0d;
      text-shadow: 1px 1px 1px #000;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .after-content-form {
    background: #006896;
    overflow: hidden;
    margin: 0;
    max-width: 100%;
    padding: 20px;
    box-shadow: none;
    .get-started-form-input {
      width: 60%;
    }

    .do-you-qualify-button {
      width: 38%;
      float: right;
      clear: none;
      padding: 0 1.5% 0 0;
    }
    button {
      margin-bottom: 0;
      padding: 11px 16px;
      border-radius: 3px;
      width: 100%;
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: 600;
      text-transform: uppercase;
      background: #1f00a9;
      text-align: left;
      position: relative;
      line-height: 24px;
      &:hover {
        background-color: #00ab66;
        .fa {
          background-color: #079031;
        }
      }

      .fa {
        position: absolute;
        right: 0;
        font-size: 20px;
        top: 0px;
        background: #18027a;
        padding: 13px 12px;
        border-radius: 0 5px 5px 0;
        margin-right: 0;
      }
    }
    input {
      background-color: #fff;
      color: #333;
      border-radius: 3px;
      font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-size: 1.6rem;
      padding: 12px;
      width: 100%;
      border: 1px solid #ccc;
    }
    h3 {
      font-size: 36px;

      color: #fff;
      //  font-weight: 300 !important;
      line-height: 36px;
      text-align: center;
      margin: 0 auto 20px;
      max-width: 100%;
      font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
      padding: 0;
    }
    .select-wrapper {
      width: 95%;
      float: left;
      padding-left: 1.5%;
    }
  }
  .get-started-form-input {
    font-family: Arial;
    font-size: 16px;
  }
}
}
.simple-content-page {
  background: url(/static/ndr_app/images/content-sidebar-wrap-bg.jpg) center top
    repeat-y;
  clear: both;
  margin: 0 auto;
  overflow: hidden;
  border-bottom: 1px solid #dee4ed;


  .content-sidebar-wrap {
    max-width: 100%;
    padding: 30px 2%;
    .mobile-only-form {
      display: none;
      h3 {
        color: #18027a;
        text-align: center !important;
        text-transform: none !important;
      }
      form {
        width: 100%;
        margin-bottom: 15px;
        max-width: 100%;
        padding: 0;
        background: transparent;
        float: none;
        h3 {
          padding-left: 0;
          margin-bottom: 20px;
          font-size: 2.1rem;
          text-align: left;
          text-transform: uppercase;
          font-weight: 400;
          line-height: 36px;
          padding: 0;
        }
        input,
        select {
          width: 100%;
          font-size: 26px;
          line-height: 32px;
          font-size: 1.6rem;
          padding: 5px;

          border-radius: 0px;
          border: 1px solid #cbccce;
          background: #fbfdfc;
          margin-bottom: 15px;
        }
      }
      h4 {
        font-size: 3.2rem;
        line-height: 32px;
        font-weight: 300;
        color: #e36d0d;
        text-shadow: 1px 1px 1px #000;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
    .after-content-form {
      background: #006896;
      overflow: hidden;
      margin: 0;
      max-width: 100%;
      padding: 20px;
      box-shadow: none;
      .get-started-form-input {
        width: 60%;
      }

      .do-you-qualify-button {
        width: 38%;
        float: right;
        clear: none;
        padding: 0 1.5% 0 0;
      }
      button {
        margin-bottom: 0;
        padding: 11px 16px;
        border-radius: 3px;
        width: 100%;
        font-size: 18px;
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: uppercase;
        background: #1f00a9;
        text-align: left;
        position: relative;
        line-height: 24px;
        &:hover {
          background-color: #00ab66;
          .fa {
            background-color: #079031;
          }
        }

        .fa {
          position: absolute;
          right: 0;
          font-size: 20px;
          top: 0px;
          background: #18027a;
          padding: 13px 12px;
          border-radius: 0 5px 5px 0;
          margin-right: 0;
        }
      }
      input {
        background-color: #fff;
        color: #333;
        border-radius: 3px;
        font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-size: 1.6rem;
        padding: 12px;
        width: 100%;
        border: 1px solid #ccc;
      }
      h3 {
        font-size: 36px;

        color: #fff;
        //  font-weight: 300 !important;
        line-height: 36px;
        text-align: center;
        margin: 0 auto 20px;
        max-width: 100%;
        font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
        padding: 0;
      }
      .select-wrapper {
        width: 95%;
        float: left;
        padding-left: 1.5%;
      }
    }
  }
  .embed-container {
    display: none;
  }

  .content {
    padding: 0;
  }
  .content-sidebar-wrap {
    overflow: hidden;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    padding: 45px 0 30px;
  }
  aside {
    display: inline;
    font-size: 16px;
    font-size: 1.6rem;
    padding-bottom: 30px;
    background: none;
    float: right;
    width: 340px;
    //padding-right: 20px;
    position: relative;
  }
}
.simple-content-wrap {
  width: 62%;
  margin: 0 auto;
  float: left;
  margin-bottom: 25px;
  padding-bottom: 30px;
  border-bottom: 2px solid #ccc;
}
.simple-content-sidebar {


  .resolve-debit {
    strong {
      font-weight: 700;
    }
  }
  .container.resolve-debit{
    padding-left:0;
    padding-right:0;
  }
  .get-started-form-submit {
    font-weight: 400;
    //  font-family: Lato;
   
    background-image: -webkit-linear-gradient(bottom, rgb(189,54,47) 0%, rgb(238,95,91) 73%, rgb(238,95,91) 100%) !important;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #FFFFFF;

    width: 100% !important;
    margin-left: 0px;
    font-size: 21px !important;

    text-transform: uppercase;
   text-shadow: 0 -1px 0  rgba(0, 0, 0, 0.75);
    font-size: 22px;
    &:hover {
      background-image: -webkit-linear-gradient(bottom, rgb(81,163,81) 0%, rgb(81,163,81) 45%, rgb(98,196,98) 100%) !important;
      background-color: #51a351 !important;
    }
  }

  .get-started-form {
    width: 100%;
    margin-bottom: 15px;
    max-width: 100%;

    padding: 0;
    .get-started-form-input {
      width: 68%;
    }

    .select-wrapper:after {
      top: 5px;
    }
    input,
    select {
      width: 100%;
      font-size: 1.6rem;
      padding: 5px;
      border-radius: 0px;
      border: 1px solid #cbccce;
      background: #fbfdfc;
      margin-bottom: 15px;
      line-height: 36px;
    }

    button {
      font-size: 2.1rem;
      text-transform: uppercase;
      width: 100%;
      display: block;
      margin: 0 auto;
      background-color: #bd362f;
      padding: 10px 0;
      border-radius: 3px;
      background: -moz-linear-gradient(
        bottom,
        rgb(189, 54, 47) 0%,
        rgb(238, 95, 91) 73%,
        rgb(238, 95, 91) 100%
      );
      &:hover {
        color: #ffffff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        background: -webkit-gradient(
          linear,
          left bottom,
          left top,
          color-stop(0, rgb(81, 163, 81)),
          color-stop(0.45, rgb(81, 163, 81)),
          color-stop(1, rgb(98, 196, 98))
        ) !important;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);

        .fa {
          background-color: #079031;
        }
      }
    }
  }

  .widget {
    margin-bottom: 50px;
    //   padding: 0px 0px 0 44px;
  }
  .resolve-debit a.button:hover,
  .resolve-debit a.button:focus {
    background: #18027a;
  }
  .resolve-debit .widget_text {
    background: #fff;
    padding: 20px 13px;
    text-align: center;
    border: 1px solid #18027A;
    border-top: 4px solid #18027A;

  }
  strong {
    font-weight: 400;
  }
  .resolve-debit ul li .fa {
    position: absolute;
    left: 0px;
    color: #00b67a;
  }
  h3 {
    padding-left: 0;
    margin-bottom: 20px;
    font-size: 22px;
    font-size: 2.2rem;
    text-align: center;
    color: #18027a;
    text-transform: none;

    padding: 0;
    /* text-transform: uppercase; */
    font-weight: 400;
    display: block;
    line-height: 1.2;
  }

  .resolve-debit ul {
    margin: 20px 0;
    padding: 0;
  }
  .resolve-debit ul li {
    position: relative;
    padding-left: 21px;
    margin-bottom: 12px;
    list-style-position: outside;
    padding: 0 0 0 20px;
    line-height: 20px;

    list-style-type: none;

    margin-top: 6px;
    text-align: left;
  }

  .resolve-debit a.button {
    text-transform: inherit;
    background: #5b9e6a;
    font-size: 18px;
    display: block;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    font-weight: 300;
    margin: 0;
    padding: 15px 45px;
  }

  .form-disclaimer {
    font-size: 14px;
    display: block;
    font-weight: 700;
    line-height: 1.2;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .simple-content-wrap {
    width: 65%;
    padding-left: 30px;
    padding-right: 30px;
  }

  /*
.simple-content-sidebar .site-inner {
    background-position: 56% top;
}*/
}

@media only screen and (max-width: 1199px) {
  .site-inner {
    background-position: 56% top;
  }
  .simple-content-page .content-sidebar-wrap .after-content-form h3 {
    font-size: 32px;
  }

}

@media only screen and (max-width: 1024px) {
    .simple-content-page .content-sidebar-wrap .mobile-only-form {
    display: block;
  }
  .simple-content-page .content-sidebar-wrap .mobile-only-form form button {
    font-size: 2.1rem;
    text-transform: uppercase;
    width: 100%;
    display: block;
    margin: 0 auto;
    background: linear-gradient(
      to bottom,
      #bd362f,
      #ee5f5b 73%,
      #ee5f5b
    ) !important;
    background: -ms-linear-gradient(
      to bottom,
      #bd362f 0,
      #ee5f5b 73%,
      #ee5f5b 100%
    ) !important;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    background: #bd362f;
    background: -moz-linear-gradient(to bottom, #bd362f, #ee5f5b 73%, #ee5f5b);
    background: -ms-linear-gradient(to bottom, #bd362f, #ee5f5b 73%, #ee5f5b);
    background: -o-linear-gradient(to bottom, #bd362f, #ee5f5b 73%, #ee5f5b);
    background: -webkit-gradient(
      linear,
      0 100%,
      0 0,
      from(#bd362f),
      color-stop(0.73, #ee5f5b),
      to(#ee5f5b)
    );
    background: -webkit-linear-gradient(
      to bottom,
      #bd362f,
      #ee5f5b 73%,
      #ee5f5b
    );
    background: linear-gradient(to bottom, #bd362f, #ee5f5b 73%, #ee5f5b);
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    padding: 10px 0;
    border-radius: 3px;
    font-weight: 300;
  }
  .sidebar-logos li a.mcafee-form {
    margin: 0 0 0 20px;
    position: relative;
    top: 30px;
  }
  .sidebar-logos {
    margin-top: 10px;
    text-align: center;
  }
  .sidebar-logos li {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .site-inner {
    background: none;
  }

  .simple-content-wrap {
    width: 100%;
  }
  .embed-container {
    display: block !important;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    display: block;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
    .simple-content-page .content-sidebar-wrap .after-content-form h3 {
    font-size: 24px;
  }
  .simple-content-page .content-sidebar-wrap .after-content-form .select-wrapper {
    width: 100%;
  }
  .simple-content-page .content-sidebar-wrap .after-content-form .get-started-form-input {
    width: 52%;
  }
  .simple-content-page .content-sidebar-wrap .after-content-form .do-you-qualify-button {
    width: 45%;
  }
  .simple-content-page  .content-sidebar-wrap .after-content-form button {
    margin-left: 0;
  }
}

@media only screen and (max-width: 560px) {
    .simple-content-page .content-sidebar-wrap .after-content-form .get-started-form-input {
    width: 100%;
    
  }
  .simple-content-page .content-sidebar-wrap .after-content-form .do-you-qualify-button {
    width: 100%;
    margin-top:15px;
  }
  .simple-content-page .simple-content-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
  .simple-content-page .content-sidebar-wrap .after-content-form {
    padding: 10px;
  }
}
