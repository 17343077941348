.freedebtconsolidationquote{

    .footer-logos {
        border-top: 1px solid #DEE4ED;
        width:100%;
    }
    .footer-logos .logos-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .footer-logos .footer-logo {
        padding: 0 10px;
    }
    .footer-logos a img {
        width: auto;
        height: auto;
        max-width: 245px;
        max-height: 100px;
        display: block;
    }
    .hjs-section {
        background: #fff;
        padding: 50px 0;
        /* overflow: hidden; */
        clear: both;
        position: relative;
    }
    .wrap{
        margin: 0 auto;
        max-width: 1350px;
        max-width: 1080px;
    }
    .footer-disclaimer {
        padding: 30px 0 70px;
        background: #F2F5F8;
        .apply-new-footer .privacy{
            margin-top:0;
        }
        .apply-new-footer .privacy p{
            font-size:10px;
            margin-bottom:10px;
        }
    }
    
}
.applyLink-col.freedebtmobileheader{
    display:none;
 
}
.main-footer.freedebttrustlogos{
    display:block;
}
@media screen and (max-width: 767px){
    .main-footer.freedebttrustlogos{
        display:none;
    }
    .sitemap-content-half{
        width:100%;
    }
    .free-debt-consolidation-quote aside{
        display:none;
    }
    .free-debt-consolidation-quote .cut-up-credit-card-debt{
        background:none;
        padding-left:0;
        padding-right:0;
        padding-top:15px;
    }
    .free-debt-consolidation-quote .cut-up-credit-card-debt li{
      
        padding-right:0;
    }
    .free-debt-consolidation-quote h3{
        font-size:24px;
    }
    .freedebtconsolidationquote .navigation-wrapper{
        width:100%;
        max-width: unset;
    }
    .applyLink-col.freedebtmobileheader{
        display:block;
     
    }
  .freedebtconsolidationquote  .navigation .header-phone{
      font-size:18px;
  }
    
}