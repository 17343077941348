.list-container {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 15px 0 0 0;
  box-shadow: 16.1px 19.2px 35px 0 rgba(0, 0, 0, 0.04);
  margin-right:0 !important;
  .filter {
    .filter-text {
      color: #535353;
      font-size: 15px;
      line-height:25px;
      font-weight: 400;
      margin-right: 5px;
      align-self:center; 
    }
    select {
      align-items: center;
      text-align: left;
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      background-size: .6em;
      background-position: bottom 14px right 8px;
      background-repeat: no-repeat;
      border-radius: 5px;
      border: 0px;
      outline: 0px;
      padding:5px;
      line-height:25px;
      font-size: 15px;
      color: #150560;
      font-weight: bold;
      width: 160px;
      cursor: pointer;
      option {
        color: initial;
        font-weight: initial;
      }
    }
    border-bottom: 1px solid #eaeaea;
    padding: 25px;
    display: flex;
    justify-content: flex-end;
  }
  .pagination-container {
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding: 40px;
    ul {
      margin: 0 !important;
      outline: 0;
      box-shadow: none;
      .page-item.active {
        display: flex !important;
        .page-link {
          color: #101930;
          background-color: transparent;
          border: none !important;
        }
      }
      .page-item.disabled .page-link{
        color:#e1e1e1;
        border:none;
      }
      .page-item > .page-link {
        &:hover {
          background-color: transparent !important
        }
      }
      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      li a {
        outline: 0;
        border: none;
        color: #a1a1a1;
        box-shadow: none;
        font-size: 24px;
      }
    }
   
  }
}

@keyframes spinAround {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(359deg) }
}

.loader {
  color: transparent !important;
  pointer-events: none;
  width: 300px;
  height: 300px;
  background-color: white;
  &:after {
    animation: spinAround 500ms infinite linear;
    border: 5px solid #101930;
    border-radius: 290486px;
    border-right-color: rgba(227, 229, 237, 0.50);
    border-top-color: rgba(227, 229, 237, 0.50);
    content: "";
    display: block;
    height: 60px;
    left: calc(50% - (15px/2));
    position: absolute !important;
    top: calc(50% - (15px/2));
    width: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .container{
  max-width:960px !important;
  }
}
@media screen and (max-width: 768px) {
  .list-container {
    max-width:unset !important; 
      background-color: white;
      width: 100%;
      height: 100%;
      padding: 15px 0 0 0;
      box-shadow: 16.1px 19.2px 35px 0 rgba(0, 0, 0, 0.04);
    .filter {
      display: flex;
      justify-content: flex-start;
      select {
        width: 160px;
      } 
    }
  }
  .loader{
    &:after{
      left: calc(44% - (15px/2));
    }
  }
  .list-container .pagination-container ul li a{
    font-size:18px;
  }
}