.navigation {
    background-color: #ffffff !important;
    padding: .5rem .5rem .5rem .5rem  !important;
    margin: 0 auto;
    display: flex;
    justify-content: space-between !important;
    p{
        margin-bottom:0;
    }
    .applyLink-col{
        display:block;
    }
    .home-header {
        background-color: #ff1300;
        border-color: #ff1300;
        border-radius: 30px;
        padding: 13px 60px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.31;
        letter-spacing: 1.3px;    
        width:257px;
        height:52px;
        margin:auto;
        display:block;
        text-align:center;
        &:hover {
            color: white;
            text-decoration: none;
        }
    }
    .navigation-wrapper{
        width:100%;
        margin:auto;
    }
}


.applyLink{
    width:100%; 
    padding-left:15px;
    
    max-width:359px;
    align-self:center;
    //margin:auto;
    white-space: nowrap;
  

}
.applyLink-col{

   
    align-self:center;
    justify-content: center;

}





@media screen and (max-width: 960px) {

    .navigation {
        flex-wrap: nowrap !important;
        padding: 10px !important;
        .row{
            flex-wrap: nowrap;
        }
        .home-header {
            padding: 10px 30px;
            font-size: 16px;
            line-height:24px;
           width:200px; 
           
           height:46px;
        }
    
       img  {
           width: 200px;
       }
    }
    .applyLink-col{
        justify-content: end;
    }
}


@media screen and (max-width: 480px) {

    .navigation {
        flex-wrap: nowrap !important;
        padding: 10px !important;
        .row{
            flex-wrap: nowrap;
        }
        .home-header {
            padding: 10px 30px;
            font-size: 13px;
            line-height:18px;
           width:100%; 
           
           height:36px;
        }
    
       img  {
           width: 200px;
       }
    }
    .applyLink-col{
        justify-content: end;
    }
    .navigation-wrapper{
        display: flex;
        flex-direction: column;
    }

}