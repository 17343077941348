
/* Widget Area */



.site-header .widget {
	padding: 0;
}

.site-header .widget.phone-widget {
	float: right;
	width: auto;
}

.site-header .widget.phone-widget p {
	font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	text-align: center;
	color: #18027A;
	font-size: 16px;

}

 .site-header .widget.phone-widget p,
 .site-header .widget.phone-widget p {
	font-size: 14px;
	
}

.site-header .widget.phone-widget p.phone {
	border: 1px solid #18027A;
	background: #fff;
	font-size: 27px;

	line-height: 27px;
	font-weight: 700;
	padding: 15px 0 16px;
    padding-right:10px;
	border-radius: 30px;
}

.site-header .widget.phone-widget p.phone a {
	color: #18027A;
}

.site-header .widget.phone-widget p.phone a small {
	display: none;
}

.site-header .widget.phone-widget p.phone .icon-wrap {
	background: #18027A;
	color: #fff;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	display: block;
	float: left;
	position: relative;
	top: -16px;
	left: -1px;
	line-height:65px;
}

.site-header .widget.phone-widget p.phone .icon-wrap .fa {
	font-size: 35px;

	position: relative;
	top: 15px;
}
.site-header .widget.phone-widget p.phone .icon-wrap svg {
	transform: rotate(
		90deg
		);
			height: 30px;
			line-height: 38px;
}

.site-header .widget.header-text-widget {
	margin-top: 27px;
	float: left;
	width: 57%;
}

.site-header .widget.header-text-widget p {
	font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 18px;
	
}

.site-header .widget p {
	margin-bottom: 0;
}

.site-header .search-form {
	float: right;
	margin-top: 24px;
	width: 50%;
}

.vendor-ie .site-header .phone-widget.widget p.phone {
	/*padding-bottom: 7px; line-height: 37px;*/
}

.site-header .widget-area .widget-left {
	float: left;
	margin-right: 15px;
}

.site-header .widget-area .widget-right {
	float: right;
	width: 256px;
    padding-top: .3125rem;
}

.site-header .widget-area .widget-left a.tree-graphic {
	display: block;
	margin-top: 17px;
}

.site-header .widget-area .widget-left a.apply-graphic {
	margin-top: 0;
	display: block;
}

.site-header .widget-area .widget-left a.apply-graphic img {
	margin-top: 6px;
}
.widget-left .top-ten-reviews-blue {
	margin-top: 30px;
	//margin: 18px 0 0;
	display: block;
}

.widget-left .top-ten-reviews-blue img {
	margin: 0 auto;
//	display: block;
    max-width:200px;
   
}
.widget-left  img {
	width:100%;
    height:auto;
    margin:auto;
   
}

.widget-left .ups-graphic {
	margin-top: 20px;
	display: block;
}

@media only screen and (max-width: 767px) {
   .site-header .widget.phone-widget p.phone .icon-wrap {
        display: block;
        float: left;
    }
   .navigation .applyLink-col{
        display:none;
    }
    .site-header .widget.phone-widget p.phone .icon-wrap {
        background: #18027A;
        color: #fff;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: block;
        float: none;
        position: static;
        top: 0;
        left: 0;
        line-height:44px;
    }
     .site-header .widget.phone-widget p.phone {
        border-radius: 50px;
        border: 1px solid #18027a;
        line-height: 1.5;
        padding: 0;
        top: 10px;
        position: relative;
        margin-bottom: 10px;
    }

    .site-header .widget.phone-widget p.phone a {
        color: #18027A;
        font-size: 20px;
        padding: 0;
        font-size: 18px;
      
        display: block;
    }

    .site-header .widget.phone-widget p.phone .icon-wrap .fa {
        font-size: 25px;
       
        position: static;
        top: 0;
    }
    .site-header .widget-area .widget-left {
        float: right;
        margin: 0;
        float: none;
        position: absolute;
        right: 5%;
        top: 0;
    }
    .site-header .widget-area .widget-right {
        width: auto;
        float: none;
        position: relative;
        top: 0;
        left: 0;
        padding: 0;
   
    }

    .site-header .widget-area .widget-left {
        float: right;
        margin: 0;
        float: none;
        position: absolute;
        right: 5%;
        top: 0;
    }
    .site-header .widget-right p.phone .icon-wrap {
        width: 45px;
        height: 45px;
        margin-top: 0;
        float: left;
    }
    .site-header .widget-right p.phone .icon-wrap .fa {
        font-size: 24px;
        font-size: 2.4rem;
        top: 10px;
    }
    .site-header .widget-right p.phone {
        font-size: 24px;
        font-size: 2.4rem;
        line-height: 24px;
        width: 180px;
        padding: 0;
        overflow: hidden;
        margin: 0 auto;
        display: flex;
    }
    .site-header .widget-right p.phone .phone-text {
        display: block;
        float: left;
        margin-left: 7px;
        line-height: 2.15;
    }

     .site-header .widget-area {
        width: 100%;
    }
    .site-header .widget.phone-widget {
        float: none;
    }
     .title-area {
        width: 100%;
    }
}

@media only screen and (max-width: 76px) {
    /*.site-header .widget.phone-widget p.phone .icon-wrap .fa {
        position: relative;
        top: 6px;
    }*/
   .header-widget-area .phone-widget{
        display:none;
    }
    .navbar{
        flex-direction:column;
    }
}

@media only screen and (max-width: 479px) {
    .site-header .widget-area .widget-right {
        left: 0;
        margin-top:0;
    }
    .site-header .widget-right p.phone {
        margin: 0 auto;
        display: flex;
    }
    .site-header .widget.phone-widget p.phone .icon-wrap .fa {
        position: relative;
        top: 6px;
    }
    .navigation-wrapper > div{
        text-align: center;    
    }
}